import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-v7',
  templateUrl: './v7.component.html',
  styleUrls: ['./v7.component.scss']
})
export class V7Component extends Canvas {

  constructor() {
    super(V7Component, 7);
  }

}
