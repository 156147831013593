import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface InstagramData {
  media_url: string;
}

export interface IResponseInstagram {
  data: InstagramData[];
  paging: { next?: string; cursor: { after: string; before: string } };
}

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  static ACCESS_TOKEN_KEY = 'access_token_instagram';
  static CODE_ACCESS_TOKEN_KEY = 'CODE_ACCESS_TOKEN_KEY';

  constructor(private http: HttpClient) {  }

  images(url?: string): Observable<any> {
    const token = localStorage.getItem(InstagramService.CODE_ACCESS_TOKEN_KEY);
    const urlInstagram = `https://graph.instagram.com/me/media?fields=media_url&access_token=${token}`;

    return this.http.get(url || urlInstagram);
  }

  login(): any {
    let path = environment.uriRedirectInstagram.replace('/instagram/instagram', '/instagram');

    if (path.charAt(path.length - 1) === '/') {
      path = path.replace(/.$/, '');
    }
    const url = `https://api.instagram.com/oauth/authorize?client_id=${environment.clientIdInstagram}&scope=user_profile,user_media&response_type=code&redirect_uri=${path}`;
    return window.open(url, '', 'width=400, height=600');
  }

  getToken(form: FormData): any {
    const headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT');
    headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization');

    return this.http.post('https://api.instagram.com/oauth/access_token', form, { headers });
  }

  updateToken() {
    return this.http.get(
      `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&&access_token=${localStorage.getItem(
        InstagramService.ACCESS_TOKEN_KEY
      )}`
    );
  }
}
