import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { Globals } from './utils/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  titulo = 'Life Wall';
  subtitulo = 'Editor de fotos';
  globals = Globals;

  constructor(private dialog: MatDialog) {
    const folderToken = localStorage.getItem('folder-token') || uuidv4();
    localStorage.setItem('folder-token', folderToken);
  }

  ngOnInit(): void {
    if (window.screen.width < 800 && !localStorage.getItem('alert-resolution')) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '90vw',
        height: 'auto',
        maxWidth: '400px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          localStorage.setItem('alert-resolution', result);
        }
      });
    }
  }
}
