<section class="container">
    <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="register()" class="row g-3">
            <div class="form-group col-12">
                <label for="address_1">Endereço 1</label>
                <input type="text" formControlName="address_1" class="form-control" autocomplete="off"
                    [(ngModel)]="address.address_1" />
            </div>
            <div class="form-group col-12">
                <label for="address_2">Endereço 2</label>
                <input type="text" formControlName="address_2" class="form-control" autocomplete="off"
                    [(ngModel)]="address.address_2" />
            </div>

            <div class="form-group col-5">
                <label for="neighborhood">Bairro</label>
                <input type="text" formControlName="neighborhood" class="form-control" autocomplete="off"
                    [(ngModel)]="address.neighborhood" />
            </div>
            <div class="form-group col-5">
                <label for="city">Cidade</label>
                <input type="text" formControlName="city" class="form-control" autocomplete="off"
                    [(ngModel)]="address.city" />
            </div>
            <div class="form-group col-2">
                <label for="state">Estado</label>
                <input type="text" formControlName="state" class="form-control" autocomplete="off"
                    [(ngModel)]="address.state" />
            </div>

            <!-- <div class="col-12">
                <div class="form-group float-right">
                    <button class="btn btn-danger mr-1" routerLink="/signature/register/editar">
                        Cancelar
                    </button>
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Salvar
                    </button>
                </div>
            </div> -->
        </form>
    </div>
</section>