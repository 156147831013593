<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="panel-title">1. Como selecionar e envias suas fotos </p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ol>
            <li>Clique em Importar no barra lateral</li>
            <li>Clique de onde deseja importar suas fotos: computador, celular, Facebook ou Instagram</li>
            <li>Marque as fotos que você deseja importar</li>
            <li>Clique no botão Importar para o Projeto e pronto suas fotos foram importadas!</li>
        </ol>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="panel-title">2. Como funciona a montagem das artes das fotos enviadas </p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ol>
            <li>Escolha o tamanho e formato do seu quadro inicial. </li>
            <li>Escolha a frequência que você deseja atualizar a arte de seu quadro (mensal, bimestral ou
                trimestral).
            </li>
            <li>Importe as fotos do seu celular, computador ou mídia social para a sua próxima arte.</li>
            <li>A Lifewall irá elaborar a arte com suas fotos importadas até o limite de 13 fotos por arte.</li>
            <li>Caso você importe mais do que 13 fotos o excedente será utilizado nas próximas artes.</li>
            <li>A Lifewall vai lhe entregar as artes prontas conforme a frequência desejada utilizando as fotos na
                sua
                GALERIA. </li>
            <li>Você vai simplesmente trocar a arte recebida no seu quadro inicial como um grande porta retrato.
            </li>
            <li>Na aba GALERIA você poderá ver as fotos importadas que serão utilizadas para a geração das artes
            </li>
            <li>Na aba ENTREGA você poderá ver as artes geradas e as fotos utilizadas</li>
            <li>Importe fotos quantas vezes desejar até que tenha na GALERIA as fotos que deseja aplicadas em artes
                para
                seu quadro inicial</li>
        </ol>

    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="panel-title">3. Dúvidas frequentes</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="faq card-overflow">
            <p class="title">1. As fotografias e arte impressa ficam boas?</p>
            <p>Sim. Utilizamos papel fotográfico premium e impressão de alta qualidade do arquivo foto enviado. O
                sistema lhe indicará se recomenda ou não o uso da foto.</p>

            <p class="title">2. Posso utilizar fotografias de whatsapp, Facebook e Instragram?</p>
            <p>Sim, pois o papel e impressão são de qualidade assim como cada vez mais as fotos. De qualquer forma o
                sistema lhe indicará se recomenda ou não o uso da foto.</p>

            <p class="title">3. Qual o limite máximo de fotos por arte?</p>
            <p>13 fotos.</p>

            <p class="title">4. Se eu importar mais ou menos que o limite máximo de fotos por arte o que ocorre?</p>
            <p>
                Se importado menos será elaborada arte com até uma fotografia ou será feita uma arte com até 10 fotos.
                <br />Se importado mais fotos, ou em caso de excedente, estas fotos serão aproveitadas nas próximas
                artes.
            </p>

            <p class="title">5. Como funciona o plano de assinatura?</p>
            <p>Você seleciona o formato e tamanho do seu quadro inicial e frequência de atualização da arte. Feito isto
                você importa suas fotos para a sua galeria e a Lifewall fará a montagem e envio das artes</p>

            <p class="title">6. Posso trocar as fotos que serão utilizadas para fazer as artes?</p>
            <p>Sim. Basta acessar sua conta na Lifewall e na GALERIA verificar as fotos importadas. Você poderá apagar
                estas fotos e adicionar novas em IMPORTAR.</p>

            <p class="title">7. Como ocorre a substituição da arte impressa no quadro recebido?</p>
            <p>É muito simples. Como se fosse um grande porta retrato, basta abrir a tampa traseira e trocar a arte.</p>

            <p class="title">8. Qual a frequência de atualização das artes?</p>
            <p>Ela pode ser mensal, bimestral ou trimestral conforme o plano escolhido por você.</p>

            <p class="title">9. Qual o formato e tamanho dos quadros?</p>
            <p>
                O formato pode ser quadrado, vertical ou horizontal. <br />
                Os tamanhos podem ser três:<br />
                Quadrado 35x35cm, 50x50cm e 80x80cm<br />
                Retangular: 30x45cm, 45x68cm e 60x90cm<br />
            </p>

            <p class="title">10. Como a Lifewall faz a elaboração das artes com minhas fotos?</p>
            <p>
                A Lifewall utilizará o aplicativo de criação e edição de arte para montagem das artes com suas fotos da
                galeria nos modelos existentes para os formatos quadrados e retangulares.
                <br />As artes poderão ser feitas em 1, 4, 6, 7, 8, 9, 10 e 13 fotos.
            </p>

            <p class="title">11. Quais são os modelos das artes que poderão ser feitas com minhas fotos?</p>
            <p>
                A Lifewall irá utilizar os modelos existentes no aplicativo de criação e edição de arte. Estes modelos
                podem ser vistos em Quadros Personalizados no website da Lifewall.
            </p>

            <p class="title">12. Os quadros tem moldura e acabamento?</p>
            <p>
                Sim. Os quadros da Lifewall tem moldura de alto padrão e acabamento com acrílico para destaque e
                proteção da arte com suas fotos.
            </p>

            <p class="title">13. Entrega em todo o Brasil e em quanto tempo?</p>
            <p>
                Entregamos em todo o Brasil e com prazo de 2 a 5 dias após a expedição.
            </p>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="panel-title">4. Modelos padrões possíveis das artes</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <dws-model-arts></dws-model-arts>
    </mat-expansion-panel>
</mat-accordion>