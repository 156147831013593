import {
  HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest,




  HttpResponse, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/models';
import { Observable, of, throwError } from 'rxjs';
import { delay, dematerialize, materialize } from 'rxjs/operators';

const usersKey = 'angular-10-registration-login-example-users';
let users = localStorage.getItem(usersKey) ? JSON.parse(localStorage.getItem(usersKey) || '{}') : [];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    return handleRoute();

    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate();
        case url.endsWith('/users/register') && method === 'POST':
          return register();
        case url.endsWith('/users') && method === 'GET':
          return getUsers();
        case url.match(/\/users\/\d+$/) && method === 'GET':
          return getUserById();
        case url.match(/\/users\/\d+$/) && method === 'PUT':
          return updateUser();
        case url.match(/\/users\/\d+$/) && method === 'DELETE':
          return deleteUser();
        default:
          return next.handle(request);
      }
    }

    function authenticate() {
      const { username, password } = body;
      const user = users.find((x: any) => x.username === username && x.password === password);
      if (!user) {
        return error('Nome do usuário ou senha incorreto.');
      }
      return ok({
        ...basicDetails(user),
        token: 'fake-jwt-token'
      });
    }

    function register() {
      const user = body;

      if (users.find((x: any) => x.username === user.username)) {
        return error('Username "' + user.username + '" is already taken');
      }

      user.id = users.length ? Math.max(...users.map((x: any) => x.id)) + 1 : 1;
      users.push(user);
      localStorage.setItem(usersKey, JSON.stringify(users));
      return ok();
    }

    function getUsers() {
      if (!isLoggedIn()) {
        return unauthorized();
      }
      return ok(users.map((x: any) => basicDetails(x)));
    }

    function getUserById() {
      if (!isLoggedIn()) {
        return unauthorized();
      }

      const user = users.find((x: any) => x.id === idFromUrl());
      return ok(basicDetails(user));
    }

    function updateUser() {
      if (!isLoggedIn()) {
        return unauthorized();
      }

      const params = body;
      const user = users.find((x: any) => x.id === idFromUrl());

      if (!params.password) {
        delete params.password;
      }

      Object.assign(user, params);
      localStorage.setItem(usersKey, JSON.stringify(users));

      return ok();
    }

    function deleteUser() {
      if (!isLoggedIn()) {
        return unauthorized();
      }

      users = users.filter((x: any) => x.id !== idFromUrl());
      localStorage.setItem(usersKey, JSON.stringify(users));
      return ok();
    }

    function ok(bodyOk?: any) {
      return of(new HttpResponse({ status: 200, body: bodyOk }))
        .pipe(delay(500));
    }

    function error(message: string) {
      return throwError({ error: { message } })
        .pipe(materialize(), delay(500), dematerialize());
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorized' } })
        .pipe(materialize(), delay(500), dematerialize());
    }

    function basicDetails(user: User) {
      const { id, username, firstName, email } = user;
      return { id, username, firstName, email };
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }

    function idFromUrl() {
      const urlParts = url.split('/');
      return parseInt(urlParts[urlParts.length - 1], 4);
    }
  }
}

export const fakeBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
