import {
  Component,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Address, Client } from '@app/models';
import {
  AlertService,
  AuthService
} from '@app/services';
import Validation from '@app/utils/confirmed.validator';
import {
  first
} from 'rxjs/operators';

@Component({
  selector: 'dws-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;
  client = {} as Client;
  address = {} as Address;
  isLogged = false;

  get f() {
    return this.registerForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      confirm_email: ['', Validators.required, Validators.email],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validators: [Validation.match('password', 'confirm_password'), Validation.match('email', 'confirm_email')]
    });

    if (this.authService.isLogged) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
      this.client = {} as Client;
    }
  }

  register() {
    this.submitted = true;
    this.alertService.clear();
    if (!this.registerForm.invalid) {
      this.loading = true;
      this.authService.register(this.registerForm.value)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.alertService.success(data.message, {
              keepAfterRouteChange: true
            });
            this.loading = false;
            this.login();
          },
          error: error => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
    }
  }

  getClient() {
    this.authService.getAccount().subscribe(data => {
      this.client = data;
    });
  }

  login(): void {
    this.authService
      .login(this.f.email.value, this.f.password.value)
      .subscribe({
        next: () => {
          this.goBackSignature();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  goAddress() {
    this.router.navigate(['/signature/address']);
  }
  
  goBackSignature() {
    this.router.navigate(['/signature/login']);
  }

}
