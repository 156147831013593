<div class="dialog">
    <div class="card">
        <div class="card-header">
            <h5>{{data.title}}</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div *ngIf="data.text; else video" class="container">
                <ul *ngIf="poster; else wallpaper">
                    <li>
                        Escolha as fotografias que você deseja ter em sua Arte com base no que elas representam para
                        você, pois posteriormente elas farão parte da sua decoração no dia a dia e lhe ajudarão a
                        relembrar o que você buscou sentir.
                    </li>
                    <li>
                        Não se preocupe em selecionar fotografias tendo como critério o tamanho do arquivo, como por
                        exemplo acima de 4 MB, pensando que esta é uma condição necessária para qualidade da sua Arte,
                        pois,na realidade, não fará diferença perceptível na impressão
                        final. No entanto evite fotografias com menos de 1 MB se o tamanho físico da fotografia na Arte
                        for grande.
                    </li>
                    <li>
                        Tenha cautela no caso de fotografias que você tenha recebido de terceiros por aplicativos.
                        Verifique antes o tamanho do arquivo, pois ao contrário das fotos que você tenha tirado de seu
                        celular ou máquina, estas talvez tenham um tamanho de arquivo muito
                        reduzido e podem não ser adequadas para impressão. Se este for o caso e você realmente desejar a
                        foto, peça o arquivo original.
                    </li>
                    <li>Por uma questão de geometria, as fotografias verticais tendem a se encaixar melhor nos pôsteres
                        de formato vertical, assim como fotografias horizontais em pôsteres de formato horizontal. O
                        mesmo é valido para as células de cada pôster,
                        conforme o formato.</li>
                    <li>
                        É a parte que lhe interessa da fotografia que determina o formato ideal, pois esta pode estar
                        centralizado, nos cantos, acima ou abaixo nas fotografias e desta forma, fotografias verticais
                        podem ser aproveitadas em formatos horizontais e vice-versa.
                    </li>
                    <li>
                        Quanto maior a resolução da fotografia mais zoom pode ser realizado,no entanto lembre-se que há
                        limite.
                    </li>
                    <li>
                        Não se limite em somente fotografias recentes. No entanto, se você estiver selecionando
                        fotografias muito antigas, verifique o tamanho do arquivo.
                    </li>
                    <li>
                        Em linhas gerais use o bom senso e verifique o resultado da imagem gerada antes de prosseguir
                        para o carrinho.
                    </li>
                </ul>

                <ng-template #wallpaper>
                    <ul>
                        <li>
                            Escolha as fotografias que você deseja ter em sua Arte com base no que elas representam para
                            você, pois posteriormente elas farão parte da sua decoração no dia a dia e lhe ajudarão a
                            relembrar o que você buscou sentir
                        </li>
                        <li>
                            Não se preocupe em selecionar fotografias tendo como critério o tamanho do arquivo, como por
                            exemplo acima de 4 MB, pensando que esta é uma condição necessária para qualidade da sua
                            Arte, pois,na realidade, não fará diferença perceptível na impressão
                            final. No entanto evite fotografias com menos de 1 MB se o tamanho físico da fotografia na
                            Arte for grande.
                        </li>
                        <li>
                            Tenha cautela no caso de fotografias que você tenha recebido de terceiros por aplicativos.
                            Verifique antes o tamanho do arquivo, pois ao contrário das fotos que você tenha tirado de
                            seu celular ou máquina, estas talvez tenham um tamanho de arquivo muito
                            reduzido e podem não ser adequadas para impressão. Se este for o caso e você realmente
                            desejar a foto, peça o arquivo original.
                        </li>
                        <li>
                            Os algoritmos dos mosaicos de papéis de parede fazem distribuição aleatória das fotografias.
                            Quanto mais fotos, menor a necessidade de repetir fotografias para preenchimento dos
                            espaços. Também, cada vez que você rodar o aplicativo, o resultado será diferente.
                        </li>
                        <li>
                            Ao final, no carrinho, você poderá comprar quantos rolos desejar para preenchimento do
                            espaço de sua parede. Como em qualquer compra de papel de parede, tenha sempre uma folga.
                        </li>
                        <li>
                            Caso deseje que cada papel de parede ou faixa seja diferente, então basta fazer a quantidade
                            de artes que você desejar e ao final do processo, no carrinho, adquirir estes produtos.
                        </li>
                        <li>
                            Tenha cautela no caso de fotografias que você tenha recebido de terceiros por aplicativos.
                            Verifique antes o tamanho do arquivo, pois ao contrário das fotos que você tenha tirado de
                            seu celular ou máquina, estas talvez tenham um tamanho de arquivo muito
                            reduzido e podem não ser adequadas para impressão. Se este for o caso e você realmente
                            desejar a foto, peça o arquivo original.
                        </li>
                        <li>
                            Não se limite em somente fotografias recentes, no entanto, se você estiver selecionando
                            fotografias muito antigas, verifique o tamanho de arquivo.
                        </li>
                        <li>
                            Em linhas gerais use o bom senso e verifique o resultado da imagem gerada antes de
                            prosseguir para o carrinho.
                        </li>
                    </ul>
                </ng-template>
            </div>

            <ng-template #video>
                <div *ngIf="poster; else wallpaperVideo">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/0NW_obiYZVQ"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>

                </div>
                <ng-template #wallpaperVideo>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9BfPi8L2hgQ"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </ng-template>
            </ng-template>
        </mat-dialog-content>

    </div>
    <mat-toolbar class="footer">
        <span style="flex: auto;"></span>
        <button type="button" class="btn btn-seize blue" (click)="onNoClick()">
            Fechar
        </button>
    </mat-toolbar>
</div>