export enum FontFamily {
    Arial = 'Arial',
    Arial_Black = 'Arial Black',
    Comic_Sans_MS = 'Comic Sans MS',
    Lato = 'Lato',
    Courier = 'Courier',
    Roboto = 'Roboto',
    Big_Shoulders_Stencil_Display = 'Big Shoulders Stencil Display',
    Kirang_Haerang = 'Kirang Haerang',
    Staatliches = 'Staatliches',
    Lobster = 'Lobster',
    Pacifico = 'Pacifico',
    Indie_Flower = 'Indie Flower',
    Caveat = 'Caveat',
    Yellowtail = 'Yellowtail',
    Cinzel = 'Cinzel',
    Satisfy = 'Satisfy',
    Courgette = 'Courgette',
    Luckiest_Guy = 'Luckiest Guy',
    Bangers = 'Bangers',
    Orbitron = 'Orbitron',
    Special_Elite = 'Special Elite',
    Monoton = 'Monoton',
    Londrina_Shadow = 'Londrina Shadow',
    Train_One = 'Train One',
    Hachi_Maru_Pop = 'Hachi Maru Pop',
}
