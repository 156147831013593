import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShippingService extends ServiceBase {

  private uri = `${environment.urlBackend}/account/profile/shipping`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  getShipping() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Token ${this.token}`
      })
    };
    return this.http.get<any>(this.uri, httpOptions).pipe(catchError(this.handleError));
  }
  
}
