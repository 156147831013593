import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Template } from '@app/models';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends ServiceBase {

  private uri = `${environment.urlBackend}/template`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  create(entity: Template): Observable<Template> {
    return this.http.post<Template>(`${this.uri}/model/`, entity, this.createOptions())
      .pipe(tap((template: Template) => {
        return template;
      }), catchError(this.handleError));
  }

  update(code: string, entity: Template | any): Observable<Template> {
    return this.http.post<Template>(`${this.uri}/model/${code}/`, entity, this.createOptions())
      .pipe(catchError(this.handleError));
  }

  delete(code: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.uri}/model/${code}/`, this.createOptions()).pipe(catchError(this.handleError));
  }

  getByFolder(): Observable<Template> {
    return this.http.get<Template>(`${this.uri}/model/`, this.createOptions()).pipe(catchError(this.handleError));
  }
}
