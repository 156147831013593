import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-v4',
  templateUrl: './v4.component.html',
  styleUrls: ['./v4.component.scss']
})
export class V4Component extends Canvas {

  constructor() {
    super(V4Component, 4);
  }
}
