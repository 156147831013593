<div class="text-info" background="#f3f5f6" (files)="dragDrop($event)" dwsDragDrop *ngIf="!images">
  <button (click)="fileInput.click()">{{textoImportarFotos}}</button>
  <input #fileInput (change)="upload($event)" type="file" hidden multiple />
</div>

<ng-container *ngIf="images">
  <div style="cursor: pointer" class="d-flex justify-content-end align-items-center px-3 mt-1">
    <mat-checkbox color="warn" (click)="updateSelection()">
      Selecionar todos
    </mat-checkbox>
  </div>
  <div class="grid">
    <dws-image-container *ngFor="let item of images" [value]="item" [loadImage]="false">
    </dws-image-container>
  </div>
</ng-container>