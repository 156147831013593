import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { CartService, Item, OrderService } from '@app/api';
import { Globals } from '@app/utils/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import domtoimage from 'dom-to-image';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(
    private cartService: CartService,
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
  ) { }

  wallpaperCheckout() {
    Globals.loadingText = 'Enviando para carrinho...';
    this.spinner.show();
    this.cartService.clearCart().subscribe(() => {
      const item: Item = {
        product_id: localStorage.getItem('sku') || '',
        quantity: 1,
        variation: {
          attribute_tamanho: localStorage.getItem('tamanho') || '',
          attribute_comprimento: localStorage.getItem('attribute_comprimento') || '',
          attribute_largura: localStorage.getItem('attribute_largura') || '',
        }
      };
      this.cartService.addItemCart(item).subscribe(resp => {
        const cartKey = localStorage.getItem('folder-token');
        localStorage.removeItem('folder-token');
        localStorage.removeItem('wasGenerated');
        localStorage.removeItem('alert-resolution');
        document.location.href = `${environment.urlFrontend}/carrinho/?cocart-load-cart=${cartKey}`;
      }).add(() => this.spinner.hide());
    });
  }

  async createPreview(node: HTMLElement): Promise<string> {
    const dataUrl = await domtoimage.toJpeg(node, { quality: 0.5 });
    return dataUrl;
  }

  async createSvgCheckout(node: HTMLElement) {
    this.spinner.show();
    const url = await domtoimage.toSvg(node);
    const link = document.createElement('a');
    link.download = '.svg';
    link.href = url;
    const xml = new XMLSerializer().serializeToString(link);
    const name = (localStorage.getItem('folder-token') || 'svgFile') + '.svg';
    const imgFile = new File([xml], name, { type: 'image/svg' });
    const sizeInMB = (imgFile.size / (1024 * 1024)).toFixed(2);
    console.log('Arquivo com: ' + sizeInMB + ' MB');
    Globals.loadingText = 'Aguarde mais um pouco, estamos salvando sua imagem, isto pode demorar alguns poucos minutos, mas já ficará pronto. Obrigado!';
    this.orderService.create(imgFile).subscribe(order => {
      this.cartService.clearCart().subscribe(() => {
        const item: Item = {
          product_id: localStorage.getItem('sku') || '',
          quantity: 1,
          variation: {
            attribute_tamanho: localStorage.getItem('tamanho') || '',
          }
        };
        this.cartService.addItemCart(item).subscribe(resp => {
          const cartKey = localStorage.getItem('folder-token');
          localStorage.removeItem('folder-token');
          localStorage.removeItem('thumb');
          localStorage.removeItem('alert-resolution');
          document.location.href = `https://lifewall.art/finalizar-compra/?cocart-load-cart=${cartKey}&file-name=${order.folderToken}`;
        }).add(() => this.spinner.hide());
      });
    });
  }
}
