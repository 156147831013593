import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';

@Directive({
  selector: '[dwsPhoneMask]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy {

  private pControl: AbstractControl;
  private pValue: string;

  @Input()
  set phoneControl(control: AbstractControl) {
    this.pControl = control;
  }
  @Input()
  set preValue(value: string) {
    this.pValue = value;
  }

  private sub: ISubscription;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.phoneValidate();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  phoneValidate() {

    this.sub = this.pControl.valueChanges.subscribe(data => {

      const preInputValue: string = this.pValue;
      const lastChar: string = preInputValue.substr(preInputValue.length - 1);

      let newVal = data.replace(/\D/g, '');

      let start = this.renderer.selectRootElement('#tel').selectionStart;
      let end = this.renderer.selectRootElement('#tel').selectionEnd;

      if (data.length < preInputValue.length) {
        if (preInputValue.length < start) {
          if (lastChar === ')') {
            newVal = newVal.substr(0, newVal.length - 1);
          }
        }

        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          newVal = newVal.replace(/^(\d{0,2})/, '($1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '($1) $2');
        } else {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(.*)/, '($1) $2-$3');
        }

        this.pControl.setValue(newVal, { emitEvent: false });

        this.renderer.selectRootElement('#tel').setSelectionRange(start, end);

      } else {

        const removedD = data.charAt(start);

        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 2) {
          newVal = newVal.replace(/^(\d{0,2})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,5})/, '($1) $2');
        } else {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,5})(.*)/, '($1) $2-$3');
        }

        if (preInputValue.length >= start) {

          if (removedD === '(') {
            start = start + 1;
            end = end + 1;
          }
          if (removedD === ')') {
            start = start + 2;
            end = end + 2;
          }
          if (removedD === '-') {
            start = start + 1;
            end = end + 1;
          }
          if (removedD === ' ') {
            start = start + 1;
            end = end + 1;
          }
          this.pControl.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement('#tel').setSelectionRange(start, end);
        } else {
          this.pControl.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement('#tel').setSelectionRange(start + 2, end + 2);
        }
      }
    });
  }

}
