import { Client } from './client.model';
import { Gallery } from './gallery.model';
import { Upload } from './upload.model';

export interface Result  {
    count?: number;
    num_pages?: number;
    results: Upload[] | Gallery[];
    token?: string;
    data?: Client;
    name: string;
    email: string;
}
