import { FileHandle } from '../models/file-handle.model';
import { TextHandle } from '../models/text-handle.model';

export class CreateObj {

    static createNewObjPhoto(current: FileHandle): FileHandle {
        return {
            file: current.file || undefined,
            strUrl: current.strUrl,
            url: current.url,
            textHandle: this.createNewObjText(current.textHandle),
            selected: current.selected,
        };
    }

    static createNewObjText(currentText?: TextHandle): TextHandle {
        if (currentText) {
            return {
                text: currentText.text,
                style: {
                    backgroundColor: currentText.style.backgroundColor,
                    color: currentText.style.color,
                    fontFamily: currentText.style.fontFamily,
                    fontSize: currentText.style.fontSize,
                    fontStyle: currentText.style.fontStyle,
                    fontWeight: currentText.style.fontWeight,
                    textDecoration: currentText.style.textDecoration,
                    textAlign: currentText.style.textAlign,
                }
            };
        }

        return {} as TextHandle;
    }
}
