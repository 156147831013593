import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dws-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss']
})
export class ApprovalDialogComponent implements AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService) { }

  ngAfterViewInit(): void {
    this.spinner.hide();
  }

  onSave(): void {
    this.dialogRef.close(this.data);
  }

  onClose(): void {
    this.dialogRef.close(undefined);
  }

  getTamanhoBorda() {
    const size = this.data.template.tamanhoBorda.replace('vw', '').trim();
    return `${Number(size) * 10} px`;
  }
}
