import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result, Upload } from '@app/models';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends ServiceBase {

  private uri = `${environment.urlBackend}/upload`;

  private urlSgnature = `http://161.97.163.176:5000`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  create(file: File, isSignature = false): Observable<Upload> {
    const formData = new FormData();
    formData.append('file', file);
    const url = isSignature ? `${this.urlSgnature}/upload/image` : `${this.uri}/images/`;

    return this.http.post<Upload>(url, formData, this.createOptions())
      .pipe(tap((res: Upload) => {
        return res;
      }), catchError(this.handleError));
  }

  update(file: File, code: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put<any>(`${this.uri}/images/${code}/`, formData, this.createOptions())
      .pipe(tap(res => res),
        catchError(this.handleError));
  }

  delete(code: string) {
    return this.http.delete(`${this.uri}/images/${code}/`, this.createOptions()).pipe(catchError(this.handleError));
  }

  getByFolder(): Observable<Result> {
    const api = `${this.uri}/images/`;
    return this.http.get<Result>(api, this.createOptions()).pipe(catchError(this.handleError));
  }

  getImageDimension(image: any): Observable<any> {
    return new Observable(observer => {
      const img = new Image();
      img.onload = event => {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      };
      img.src = image.strUrl;
    });
  }

  //Signarure
  getPhotos(): Observable<Result> {
    const api = `${this.urlSgnature}/upload/image`;
    return this.http.get<Result>(api, this.createOptions()).pipe(catchError(this.handleError));
  }
}
