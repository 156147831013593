import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h8',
  templateUrl: './h8.component.html',
  styleUrls: ['./h8.component.scss']
})
export class H8Component extends Canvas {

  constructor() {
    super(H8Component, 8);
   }

}
