import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (route.queryParams.sku) {
      const sku = route.queryParams.sku.split('-')[1] || '';
      localStorage.setItem('sku', sku);
    }
    if (route.queryParams.tamanho) {
      localStorage.setItem('tamanho', route.queryParams.tamanho);
    }
    if (route.queryParams.formato) {
      localStorage.setItem('formato', route.queryParams.formato);
    }

    if (this.auth.isLogged) {
      return true;
    }
    this.router.navigate(['/signature/login']);
    return false;


  }
}
