export interface Address {
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country : string;
    number: string;
    neighborhood: string;
}
