import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { InstagramService } from './components/upload-dialog/services/instagram.service';

declare const window: any;
declare const FB: any;

export function appInitializer(http: HttpClient): any {
  return () =>
    new Promise<void>((resolve) => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: environment.clientIdFacebook,
          xfbml: true,
          version: 'v11.0',
          localStorage: true,
          status: true,
        });
      };

      if (
        location.href.includes('instagram') &&
        location.href.includes('code=')
      ) {
        const code = location.href.split('code=')[1];
        localStorage.setItem(
          InstagramService.CODE_ACCESS_TOKEN_KEY,
          code.replace('#_', '')
        );
      }

      // load facebook sdk script
      (function (d: Document, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s) as HTMLImageElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs!.parentNode!.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      resolve();
    });
}
