import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Address, Client } from '@app/models';
import { AuthService, AlertService } from '@app/services';
import Validation from '@app/utils/confirmed.validator';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dws-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;
  client = {} as Client;
  address = {} as Address;
  isLogged = false;

  produto: {
    totalFotos: string,
    descricao?: string,
    tamanho: string,
    formato: string,
  };

  get f() {
    return this.registerForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.dadosProduto();
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      confirm_email: ['', Validators.required, Validators.email],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validators: [Validation.match('password', 'confirm_password')]
    });

    if (this.authService.isLogged) {
      this.getClient();
      this.isLogged = true;
    } else {
      this.isLogged = false;
      this.client = {} as Client;
    }

  }

  register() {
    this.submitted = true;
    this.alertService.clear();
    if (!this.registerForm.invalid) {
      this.loading = true;
      this.authService.register(this.registerForm.value)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.alertService.success(data.message, {
              keepAfterRouteChange: true
            });
            this.loading = false;
            this.login();
          },
          error: error => {
            console.log(error);

            this.alertService.error(error);
            this.loading = false;
          }
        });
    }
  }

  getClient() {
    this.authService.getAccount().subscribe(data => {
      this.client = data;
    });
  }

  login(): void {
    this.authService
      .login(this.f.email.value, this.f.password.value)
      .subscribe({
        next: () => {
          this.goBackSignature();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  goAddress() {
    this.router.navigate(['/signature/address']);
  }

  goBackSignature() {
    this.router.navigate(['/signature/login']);
  }

  private dadosProduto() {
    const sku = localStorage.getItem('sku');
    switch (sku) {
      case 'q12_mensal_mod1':
        this.produto = {
          totalFotos: '30 fotos',
          descricao: 'Quadro e 12 artes fotográficas personalizadas no ano (mensal)',
          tamanho: localStorage.getItem('tamanho') || '',
          formato: localStorage.getItem('formato') || '',
        }
        return this.produto;

      default:
        return '';
    }
  }

}
