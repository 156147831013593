<mat-grid-list [gutterSize]="gutterValue" cols="6" rowHeight="5:6.5">
  <mat-grid-tile colspan="2" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(0)" [dropData]='model.cell00'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="2" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(1)" [dropData]='model.cell01'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="2" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(2)" [dropData]='model.cell02'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="2" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(3)" [dropData]='model.cell03'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="2" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(4)" [dropData]='model.cell04'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="1" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(5)" [dropData]='model.cell05'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="1" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(6)" [dropData]='model.cell06'></dws-canvas-base>
    </div>
  </mat-grid-tile>

  <mat-grid-tile colspan="6" rowspan="1">
    <div class="cell">
      <dws-canvas-base [connectionId]="generateIdConnection(7)" [dropData]='model.cell07'></dws-canvas-base>
    </div>
  </mat-grid-tile>
</mat-grid-list>
