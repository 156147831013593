<section class="container">
  <div class="title">
    <h2>PAPEL DE PAREDE</h2>
    <hr />
  </div>
</section>

<section class="container">
  <div class="row">
    <div class="col-12">
      <h2>Material</h2>
      <hr />
      <p>{{ material }}</p>
    </div>
    <div class="col-12">
      <h2>Dimensão</h2>
      <hr />
      <p *ngIf="orientation === 'horizontal'">Comprimento: {{ comprimento }} cm</p>
      <p>Largura: {{ largura }} cm</p>
    </div>
  </div>
</section>

<section class="container">
  <div class="row">
    <div class="col-12">
      <h2>Quantidade de fotos</h2>
      <hr />
      <p>
        Mínima: {{ qtdImagensRequeridas }}<br />
        Máxima: {{ qtdIdeal }}
      </p>
    </div>
  </div>
</section>

<section class="container" *ngIf="sku === 'pp_h_mod01' || sku === 'pp_v_mod01'">
  <h2>Estilo Molduras de Pretas</h2>
  <hr />
  <div *ngIf="sku === 'pp_h_mod01'">
    <img
      src="https://lifewall.art/wp-content/uploads/2021/03/Horizontal-idealmente-futebol-crianca-em-preto-e-branco-as-fotos-4.6-PRETO-14Fotos-300x1500-Colorida.jpg"
      width="100%">
  </div>
  <div *ngIf="sku === 'pp_v_mod01'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/MOLDURAS-PRETO-E-BRANCO.png" width="100%">
  </div>
</section>

<section class="container" *ngIf="sku === 'pp_h_mod02' || sku === 'pp_v_mod02'">
  <h2>Estilo Molduras de Madeira</h2>
  <hr />
  <div *ngIf="sku === 'pp_h_mod02'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/Praias-e-por-de-sol-7.4-MADEIRA-7-Fotos-150x80-1.jpg"
      width="100%">
  </div>
  <div *ngIf="sku === 'pp_v_mod02'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/MOLDURAS-MADEIRINHAS.png" width="100%">
  </div>
</section>

<section class="container" *ngIf="sku === 'pp_h_mod03' || sku === 'pp_v_mod03'">
  <h2>Estilo Collage</h2>
  <hr />
  <div *ngIf="sku === 'pp_h_mod03'">
    <img
      src="https://lifewall.art/wp-content/uploads/2021/03/Horizontal-lugares-no-mundo-3.6-Ortogonal-300x1500-Colorida-768x384.jpg"
      width="100%">
  </div>
  <div *ngIf="sku === 'pp_v_mod03'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/COLLAGE.png" width="100%">
  </div>
</section>

<section class="container" *ngIf="sku === 'pp_h_mod04' || sku === 'pp_v_mod04'">
  <h2>Estilo Grid</h2>
  <hr />
  <div *ngIf="sku === 'pp_h_mod04'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/HORIZONTAL_TEMPLATE_GRID_SPORTS_v500-600.jpg"
      width="100%">
  </div>
  <div *ngIf="sku === 'pp_v_mod04'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/VERTICAL_TEMPLATE_GRID-1-1_SPORTS_v500-600.jpg"
      width="100%">
  </div>
</section>

<section class="container" *ngIf="sku === 'pp_h_mod05' || sku === 'pp_v_mod05'">
  <h2>Estilo Grid Rotation</h2>
  <hr />
  <hr />
  <div *ngIf="sku === 'pp_h_mod05'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/horizontal-pasta-pessoas-Turismo-1.6disperso.jpg"
      width="100%">
  </div>
  <div *ngIf="sku === 'pp_v_mod05'">
    <img src="https://lifewall.art/wp-content/uploads/2021/03/GRID-ROTATION.png" width="100%">
  </div>
</section>
