import { Component, OnInit } from '@angular/core';
import { OrientationEnum } from '@app/helpers/enumerators';

@Component({
  selector: 'dws-settings-wallpaper',
  templateUrl: './settings-wallpaper.component.html',
  styleUrls: ['./settings-wallpaper.component.scss']
})
export class SettingsWallpaperComponent implements OnInit {

  orientationTemplate = OrientationEnum;
  orientationEnum: OrientationEnum;

  typeFinishing: string;
  typeRoll: string;

  constructor() { }

  ngOnInit(): void {
  }

  clickOrientation(value: OrientationEnum) {
    this.orientationEnum = value;
  }

  clickFinishing(type: string) {
    this.typeFinishing = type;
  }

  clickRoll(type: string) {
    this.typeRoll = type;
  }
}
