<div class="text-info" *ngIf="!status">
  <p>
    Para importar as fotos do Facebook, você precisará se autenticar com seu
    nome de usuário e senha do Facebook.
  </p>

  <p>
    Nota: A LIFEWALL Photo não salvará seu nome de usuário e senha do Facebook.
    <br />Essas informações são usadas pelo Facebook para autenticar o usuário e
    recuperar as fotos. <br />É totalmente seguro usar este recurso.
  </p>
  <button (click)="login()">Autorizar</button>
</div>

<div *ngIf="status">
  <div
    style="cursor: pointer"
    class="d-flex justify-content-end align-items-center px-3 mt-1"
  >
    <mat-checkbox color="warn" (click)="updateSelection()">
      Selecionar todos
    </mat-checkbox>
  </div>

  <div class="d-flex flex-column" *ngFor="let album of albumsData">
    <p class="text-uppercase text-black-50 px-3 pt-2 text-body">
      {{ album.albumName }}
    </p>

    <div class="grid">
      <dws-image-container
        *ngFor="let item of album.images"
        [value]="item"
        [imagePath]="item?.minImage.source"
      >
      </dws-image-container>
    </div>
  </div>
</div>
