<dws-header>
    <div class="header-logo">
        <h1>{{ titulo }}</h1>
    </div>
</dws-header>

<dws-content>
    <dws-alert></dws-alert>
    <router-outlet></router-outlet>
</dws-content>

<dws-footer>
    <div class="footer">Lifewall © 2020 - Copyright</div>
</dws-footer>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ffffff" type="ball-clip-rotate-multiple" [fullScreen]="true">
    <p style="color: white; text-align: center;"> {{ globals.loadingText }} </p>
</ngx-spinner>