<span style="cursor: pointer" class="close-button" (click)="onNoClick(false)">
  <mat-icon>close</mat-icon>
</span>
<div class="title">
  <h2>Galeria de fotos</h2>
</div>
<mat-dialog-content class="mat-typography">
  <section class="container">
    <div class="content">
      <button class="btn btn-seize red" (click)="openDialogUpload()">Adicionar fotos a galeria</button>
      <button class="btn btn-seize blue" (click)="onNoClick(true)">Apagar foto na celula</button>
      <input hidden type="file" #fileInput (change)="filesInput($event)" multiple>
    </div>
  </section>

  <hr />

  <section class="container" *ngIf="files.length > 0">
    <div class="row" cdkDropList id="dropPhoto">
      <div *ngFor="let file of files; let i = index" style="height: 100%;">
        <dws-upload-task [file]="file" [index]='i' (toExclude)="deleteImage($event)" (toSelect)="selectImage($event)">
        </dws-upload-task>
      </div>
    </div>
  </section>
</mat-dialog-content>