<div class="scattered-template {{ urlImage ? 'color' : '' }}">
  <div *ngIf="urlImage" cdkDrag>
    <img [src]="urlImage" [class]="orientacao" [ngStyle]="{ transform: 'scale(' + scale / 100 + ')'}" />
  </div>
  <div *ngIf="urlImage && isWallpaper">
    <button class="btn zoom-in" mat-icon-button (click)="zoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button class="btn zoom-out" mat-icon-button (click)="zoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </button>
  </div>
</div>