import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { BaseWrapper } from '../base-wrapper/base-wrapper.component';
import { DropboxService } from '../services/dropbox.service';

declare const FB: any;

interface DropboxData {
  link: string;
  metadata: MetaData;
}

interface MetaData {
  client_modified: string;
  content_hash: string;
  id: string;
  is_downloadable: boolean;
  name: string;
  path_display: string;
  path_lower: string;
  rev: string;
  server_modified: string;
  size: number;
}

@Component({
  selector: 'dws-dropbox-wrapper',
  templateUrl: './dropbox-wrapper.component.html',
  styleUrls: ['./dropbox-wrapper.component.scss'],
})
export class DropboxWrapperComponent extends BaseWrapper implements OnInit {
  images$: Observable<DropboxData[]>;
  componentName = 'dropbox';

  constructor(public dropboxService: DropboxService) {
    super();
  }

  openDropbox(): void {
    window.open(
      `https://www.dropbox.com/oauth2/authorize?client_id=${environment.clientIdDropbox}&response_type=code&redirect_uri=${environment.uriRedirectDrobpbox}`
    );
  }

  ngOnInit(): void {
    if (localStorage.getItem(DropboxService.ACCESS_TOKEN_KEY)) {
      this.images$ = this.dropboxService.images();
    }
  }
}
