import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import {
  BaseWrapper,
  BaseWrapperEnum
} from '../base-wrapper/base-wrapper.component';
import {
  InstagramData,
  InstagramService,
  IResponseInstagram
} from '../services/instagram.service';

@Component({
  selector: 'dws-instragram-wrapper',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})
export class InstragramWrapperComponent extends BaseWrapper implements OnInit {
  name = BaseWrapperEnum.INSTRAGRAM;
  images: InstagramData[] = [];

  constructor(
    private instagramService: InstagramService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (location.href.includes('code=')) {
      const code = location.href.split('code=')[1];
      localStorage.setItem(InstagramService.CODE_ACCESS_TOKEN_KEY, code.replace('#_', ''));
      if (location.href.includes('instagram')) {
        this.getImages(code.replace('#_', ''));
        this.ref.detectChanges();
      }
    }
  }

  getInstagramUrl() {
    const FIND = '/instagram/instagram/';
    const REPLACE = '/instagram/';

    let url = `${environment.uriRedirectInstagram.replace(FIND, REPLACE)}`;

    if (url.charAt(url.length - 1) === '/') {
      url = url.replace(/.$/, '');
    }

    return url;
  }

  login() {
    const instaWindow = this.instagramService.login();
    const timeout = setInterval(() => {
      if (localStorage.getItem(InstagramService.CODE_ACCESS_TOKEN_KEY) !== null) {
        clearInterval(timeout);
        instaWindow.close();
        this.getImages();
      }
    }, 100);
  }

  getImages(path?: string): void {
    this.instagramService.images(path).subscribe(
      (data: IResponseInstagram) => {
        if (data !== null) {
          this.images = [...this.images, ...data?.data];
          if (data?.paging?.next) {
            this.getImages(data?.paging?.next);
          }
        }
      },
      () => {
        localStorage.removeItem(InstagramService.ACCESS_TOKEN_KEY);
        localStorage.removeItem(InstagramService.CODE_ACCESS_TOKEN_KEY);
      }
    );
  }
}
