import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dws-wallpaper',
  templateUrl: './wallpaper.component.html',
  styleUrls: ['./wallpaper.component.scss']
})
export class WallpaperComponent implements OnInit {

  orientacao: string;

  constructor() { }

  ngOnInit(): void {
    this.orientacao = localStorage.getItem('orientation') || '';
  }

}
