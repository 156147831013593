import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShippingService } from '@app/api';
import { Address } from '@app/models/address.model';
import { AlertService, AuthService } from '@app/services';

@Component({
  selector: 'dws-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() address = {} as Address;

  registerForm: FormGroup;
  loading = false;
  submitted = false;

  get f() {
    return this.registerForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private shippingService: ShippingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      address_1: [''],
      address_2: [''],
      city: [''],
      state: [''],
      postcode: [''],
      country: [''],
      number: [''],
      neighborhood: [''],
    });

    this.shippingService.getShipping().subscribe(res => this.address = res);
  }

  register() {

  }

  goBackSignature() {
    this.router.navigate(['/signature']);
  }

}
