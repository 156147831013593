import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UploadDialogComponent } from '@app/components/upload-dialog/upload-dialog.component';
import { IconMenu } from '@app/helpers/enumerators';
import { ApplicationName } from '@app/helpers/enumerators/application-name.enum';
import { FileHandle, ItemMenu } from '@app/models';
import { MenuService } from '@app/services/menu.service';
import { RefreshService } from '@app/services/refresh.service';
import { first } from 'rxjs/operators';
import { AuthService } from './../../services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'dws-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  files: FileHandle[] = [];
  itemsMenu: ItemMenu[] = [];
  tipo: IconMenu;
  iconMenu = IconMenu;
  open = false;
  options = { autoHide: false, scrollbarMinSize: 80 };

  menuWallpaper = false;

  loggedIn = false;

  get isMobile() {
    if (window.screen.width < 800) {
      return true;
    }
    return false;
  }

  get isSignature() {
    return location.href.includes('signature');
  }

  constructor(
    private router: Router,
    menuService: MenuService,
    private authService: AuthService,
    private refreshService: RefreshService,
    public dialog: MatDialog) {

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.itemsMenu = [];
        this.menuWallpaper = true;
        if (val.url.indexOf('signature') > 0) {
          localStorage.setItem('produto', 'signature');
          this.itemsMenu = menuService.getItensMenu(ApplicationName.signature);
          this.menuWallpaper = false;
        } else if (val.url.indexOf('papel-de-parede') > 0) {
          localStorage.setItem('produto', 'papel-de-parede');
          this.itemsMenu = menuService.getItensMenu(ApplicationName.wallpaper);
        } else {
          localStorage.setItem('produto', 'poster');
          this.itemsMenu = menuService.getItensMenu(ApplicationName.collage);
        }

        if (location.href.includes('instagram')) {
          const menu = this.itemsMenu.find(({ text }) => text === 'Importar') as ItemMenu;
          this.toggle(menu);
        }
      }
    });
  }

  ngOnInit(): void {
    this.authService.logged$.subscribe(() => {
      this.loggedIn = this.authService.isLogged;
    });
  }

  getIconName(index: number) {
    return IconMenu[index];
  }

  toggle(tipo: ItemMenu) {
    if (this.tipo === tipo.icon) {
      this.open = !this.open;
    } else {
      this.tipo = tipo.icon;
      this.open = true;
    }
  }

  clickMenuSignature(item: ItemMenu) {
    switch (item.icon) {
      case IconMenu.gallery:
        this.router.navigate(['signature/photos']);
        break;

      case IconMenu.upload:
        this.openDialogUpload();
        this.router.navigate(['signature/photos']);
        break;

      case IconMenu.settings:
        this.router.navigate(['signature/delivery']);
        break;

      default:
        this.router.navigate(['signature']);
        break;
    }
  }

  private openDialogUpload() {
    const dialog = this.dialog.open(UploadDialogComponent, {
      maxWidth: '98vw',
      height: window.innerWidth < 420 ? '90vh' : '680px',
      width: window.innerWidth < 420 ? '93vw' : '960px',
      panelClass: 'upload-modal-container',
      disableClose: true,
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((files: FileHandle[]) => {
        if (files && files.length > 0) {
          this.files = [...this.files, ...files];
        }
        this.refreshService.setRefresh(true);
      });
  }
}
