<section class="content">
  <section class="container">

    <div class="title">
      <h1>Adicione o seu texto</h1>
      <hr />
    </div>

    <div class="form-group">
      <label for="textArea">Escreva seu texto</label>
      <textarea class="form-control" rows="2" [value]="selectedText" (keyup)="changeText($event)"></textarea>
    </div>

    <div class="form-group">
      <label for="dimensao">Dimensão</label>
      <div class="input-group">
        <input class="form-control" [value]="selectedSize" type="number" min="8" max="75"
          (change)="changeFontSize($event)" />
        <button class="btn btn-outline-secondary" type="button"
          [ngClass]="{active: textStyle.fontWeight === format.bold}" (click)="changeTextFormat(format.bold)">
          <i class="bi bi-type-bold"></i>
        </button>
        <button class="btn btn-outline-secondary" type="button"
          [ngClass]="{active: textStyle.fontStyle === format.italic}" (click)="changeTextFormat(format.italic)">
          <i class="bi bi-type-italic"></i>
        </button>
        <button class="btn btn-outline-secondary" type="button"
          [ngClass]="{active: textStyle.textDecoration === format.underline}"
          (click)="changeTextFormat(format.underline)">
          <i class="bi bi-type-underline"></i>
        </button>
      </div>
    </div>

    <div class="form-group">
      <label for="fonte">Fonte</label>
      <div class="input-group">
        <select class="form-control" (change)="changeFontFamily($event)">
          <option *ngFor="let enum of fontFamilyEnum | keyvalue" value="{{ enum.value }}"
            [style.font-family]="enum.value">
            {{ enum.value }}
          </option>
        </select>
        <input class="form-control" type="color" value="{{selectedColorFont}}" [(ngModel)]="selectedColorFont"
          (change)="changeFontColor($event)">
      </div>
    </div>

    <div class="form-group">
      <label for="color-input">Segure e arraste o texto</label>
      <hr />
      <div class="input-group cursor">
        <div class="drop-text" cdkDropList id="dropText" [cdkDropListData]="files"
          [cdkDropListConnectedTo]="connectedTo" [style.backgroundColor]="'var(--color-gray-40)'">
          <span [ngStyle]="textHandle?.style || null" cdkDrag>
            {{textHandle.text}}
          </span>
        </div>
      </div>
    </div>
  </section>
</section>