<div class="title">
  <h1>PAPEL DE PAREDE</h1>
</div>

<section class="container">
  <h2>Acabamento:</h2>
  <hr />

  <div class="row">
    <div class="col-12">
      <div class="box">
        <dws-icon-seize (click)="clickFinishing('brillant')" icon="brillant" width="50px" height="50px"
          [color]="typeFinishing === 'brillant' ? 'var(--color-red-base)' : 'var(--color-gray-60)'"></dws-icon-seize>
      </div>
      <div class="box-text group-1" [ngClass]="{active: typeFinishing === 'brillant'}">
        <p>Brilhante</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <dws-icon-seize (click)="clickFinishing('frosted')" icon="frosted" width="50px" height="50px"
          [color]="typeFinishing === 'frosted' ? 'var(--color-red-base)' : 'var(--color-gray-60)'"></dws-icon-seize>
      </div>
      <div class="box-text group-1" [ngClass]="{active: typeFinishing === 'frosted'}">
        <p>Fosco</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <dws-icon-seize (click)="clickFinishing('textured')" icon="textured" width="50px" height="50px"
          [color]="typeFinishing === 'textured' ? 'var(--color-red-base)' : 'var(--color-gray-60)'"></dws-icon-seize>
      </div>
      <div class="box-text group-1" [ngClass]="{active: typeFinishing === 'textured'}">
        <p>Texturizado</p>
      </div>
    </div>
  </div>
</section>

<section class="container">
  <h2>Orientação:</h2>
  <hr />
  <div class="box-2" (click)="clickOrientation(orientationTemplate.square)"
    [ngClass]="{orientation: orientationEnum === orientationTemplate.square}">
    <div class="square"></div>
    <p>Quadrado</p>
  </div>
  <div class="box-2" (click)="clickOrientation(orientationTemplate.vertical)"
    [ngClass]="{orientation: orientationEnum === orientationTemplate.vertical}">
    <div class="vertical"></div>
    <p>Vertical</p>
  </div>
  <div class="box-2" (click)="clickOrientation(orientationTemplate.horizontal)"
    [ngClass]="{orientation: orientationEnum === orientationTemplate.horizontal}">
    <div class="horizontal"></div>
    <p>Horizontal</p>
  </div>
</section>

<section class="container">
  <h2>Largura do Rolo do Papel:</h2>
  <hr />
  <div class="row">
    <div class="col-12">
      <div class="box">
        <dws-icon-seize icon="paper-roll-80" width="50px" height="50px" (click)="clickRoll('roll-80')"
          [color]="typeRoll === 'roll-80' ? 'var(--color-red-base)' : 'var(--color-gray-40)'"></dws-icon-seize>
      </div>
      <div class="box-text group-2" [ngClass]="{active: typeRoll === 'roll-80'}">
        <p>Largura 80 cm<br />Altura 280 cm</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="box roll-150">
        <dws-icon-seize icon="paper-roll-150" width="72px" height="52px" (click)="clickRoll('roll-150')"
          [color]="typeRoll === 'roll-150' ? 'var(--color-red-base)' : 'var(--color-gray-40)'"></dws-icon-seize>
      </div>
      <div class="box-text group-2" [ngClass]="{active: typeRoll === 'roll-150'}">
        <p>Largura 150 cm<br />Altura 280 cm</p>
      </div>
    </div>
  </div>
</section>
