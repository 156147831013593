import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, AuthService } from '@app/services';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dws-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loading = false;
  submitted = false;
  hasError = false;
  isValidFormSubmitted = false;
  loginForm = {} as FormGroup;

  private unsubscribe: Subscription[] = [];

  get loginControls() {
    return this.loginForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) {
    if (this.authService.logged$) {
      this.router.navigate(['/signature/photos']);
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  login(): void {
    this.hasError = false;
    this.isValidFormSubmitted = true;
    this.alertService.clear();

    if (this.loginForm.valid) {
      this.isValidFormSubmitted = false;
      const loginSubscr = this.authService
        .login(this.loginControls.email.value, this.loginControls.password.value)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.router.navigate(['signature/photos']);
            } else {
              this.hasError = true;
              this.alertService.warn('Usuário ou senha inválidos', 'Login inválido');
            }
            this.loading = false;
          },
          error: error => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
      this.unsubscribe.push(loginSubscr);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  openRegister() {
    this.router.navigate(['/signature/register']);
  }

}
