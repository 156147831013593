import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutBase } from '@app/pages/layouts/layout-base';
import { Globals } from '@app/utils/globals';

@Component({
  selector: 'dws-scattered',
  templateUrl: './scattered.component.html',
  styleUrls: ['./scattered.component.scss']
})
export class ScatteredComponent extends LayoutBase implements OnInit {

  get urlImage() {
    return Globals.urlWallpaper;
  }

  constructor(route: ActivatedRoute, location: Location) {
    super(route, location);
  }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--wallpaper-background-color', '#FFFFFF');
  }
}
