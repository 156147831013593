<div class="card-overflow">
    <section>
        <div class="font-weight-bold">Quadrado</div>
        <hr class="mt-2 mb-1">
        <div class="card-group">
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/1.jpg" alt="1 foto">
                <h6 class="card-title">1 foto</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/4.png" alt="4 fotos">
                <h6 class="card-title">4 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/8_1.png" alt="8 fotos">
                <h6 class="card-title">8 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/8_2.png" alt="8 fotos">
                <h6 class="card-title">8 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/9.png" alt="9 fotos">
                <h6 class="card-title">9 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/10.png" alt="10 fotos">
                <h6 class="card-title">10 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/quadrado/13.png" alt="13 fotos">
                <h6 class="card-title">13 fotos</h6>
            </div>
        </div>
    </section>
    <section>
        <div class="font-weight-bold">Vertical</div>
        <hr class="mt-2 mb-1">
        <div class="card-group">
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/1.jpg" alt="1 foto">
                <h6 class="card-title">1 foto</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/4.png" alt="4 fotos">
                <h6 class="card-title">4 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/7.png" alt="7 fotos">
                <h6 class="card-title">8 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/8.png" alt="8 fotos">
                <h6 class="card-title">9 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/9.png" alt="9 fotos">
                <h6 class="card-title">10 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/vertical/13.png" alt="13 fotos">
                <h6 class="card-title">13 fotos</h6>
            </div>
        </div>
    </section>
    <section>
        <div class="font-weight-bold">Horizontal</div>
        <hr class="mt-2 mb-1">
        <div class="card-group">
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/1.png" alt="1 foto">
                <h6 class="card-title">1 foto</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/4.png" alt="4 fotos">
                <h6 class="card-title">4 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/6.png" alt="6 fotos">
                <h6 class="card-title">8 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/8_1.png" alt="8 fotos">
                <h6 class="card-title">9 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/8_2.png" alt="8 fotos">
                <h6 class="card-title">10 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/13_1.png" alt="13 fotos">
                <h6 class="card-title">13 fotos</h6>
            </div>
            <div class="img-fluid col-sm-2 text-center">
                <img class="card-img-top" src="assets/images/horizontal/13_2.png" alt="13 fotos">
                <h6 class="card-title">13 fotos</h6>
            </div>
        </div>
    </section>
</div>