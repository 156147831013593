import { Component } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h4',
  templateUrl: './h4.component.html',
  styleUrls: ['./h4.component.scss']
})
export class H4Component extends Canvas {

  constructor() {
    super(H4Component, 4);
   }

}
