import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutBase } from '../layout-base';

@Component({
  selector: 'dws-square',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.scss'],
})
export class SquareComponent extends LayoutBase {

  constructor(route: ActivatedRoute, location: Location) {
    super(route, location);
  }
}
