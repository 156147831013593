<div *ngIf="isCode;else noCode" style="text-align: center;">
    <h2 mat-dialog-title>{{titleCode}}</h2>
    <div mat-dialog-content>
        <h5>{{code}}</h5>
    </div>
    <div mat-dialog-actions>
        <span style="flex: auto;"></span>
        <button (click)="onNoClick(reload)" class="btn btn-seize red">Fechar</button>
    </div>
</div>

<ng-template #noCode>
    <h1 mat-dialog-title>{{ isModalSave ? 'Transferência entre dispositivos ' : 'Recuperar fotos ' }}na Lifewall</h1>
    <div mat-dialog-content>
        <form [formGroup]="codeForm" novalidate>
            <div class="form-group">
                <label for="tel">Digite seu celular:</label>
                <input id="tel" class="form-control" dwsPhoneMask [preValue]="codeForm.value.phone"
                    [phoneControl]="codeForm.controls['phone']" formControlName="phone" maxlength="15"
                    [ngClass]="(codeForm.controls['phone'].invalid) ? 'errMsg' : 'validMsg'">
            </div>
            <div class="form-group" *ngIf="!isModalSave">
                <label for="code">Inserir seu código enviado por SMS:</label>
                <input id="code" class="form-control" formControlName="code" maxlength="6"
                    [ngClass]="(codeForm.controls['code'].invalid) ? 'errMsg' : 'validMsg'">
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button (click)="onNoClick(false)" class="btn btn-seize blue">Fechar</button>
        <span style="flex: auto;"></span>
        <button [disabled]="isValidButton" class="btn btn-seize red" [ngClass]='{disabled: isValidButton}' (click)="submitModal()">
            {{ isModalSave ? 'Salvar' : 'Recuperar' }}</button>
    </div>
</ng-template>