<div class="container">
  <h2 class="fw-light text-center text-lg-start mt-4 mb-0">Fotos para quadro</h2>
  <div class="row">
    <div class="col-md-6 fw-light text-lg-start mt-4 mb-0">
      Upload
    </div>
    <div class="col-md-6 text-right w-light text-lg-start mt-4 mb-0">
      {{files.length}}/{{maxPhotos}}
    </div>
  </div>
  <div class="progress">
    <div class="progress-bar" role="progressbar" [ngStyle]="{'width':porcentagem + '%'}" aria-valuemin="0"
      aria-valuemax="100">
      {{porcentagem}}%
    </div>
  </div>
  <hr class="mt-2 mb-5">
  <div class="gallery" id="gallery">
    <div class="mb-3 pics animation all 2" *ngFor="let file of files; let i = index">
      ​<picture>
        <div class="item img-wrap text-center">
          <button mat-icon-button class="close" (click)="exclude(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <label>
            <img [src]="file.url" class="rounded mx-auto d-block">
          </label>
          <span *ngIf="file.baixaresolucao" class="notify-badge">Imagem de menor resolução</span>
        </div>
      </picture>
    </div>
  </div>
</div>
