import { ImageGalleryModalComponent } from './../../mobile/components/image-gallery-modal/image-gallery-modal.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FileHandle } from 'src/app/models/file-handle.model';
import { TextHandle } from 'src/app/models/text-handle.model';
import { DragDropShareService } from '@app/services/drag-drop-share.service';
import { CreateObj } from 'src/app/utils/create-obj';
import { MatDialog } from '@angular/material/dialog';
import { EditorPhotoComponent } from '../editor-photo/editor-photo.component';
import { Globals } from '@app/utils/globals';

@Component({
  selector: 'dws-canvas-base',
  templateUrl: './canvas-base.component.html',
  styleUrls: ['./canvas-base.component.scss']
})
export class CanvasBaseComponent {

  @Input() dropData: FileHandle[] = [];
  @Input() connectionId: string;
  @Input() connectedTo = ['dropPhoto', 'dropText', 'dropGallery'];

  scale = 100;
  urlUpload: SafeUrl;
  listSticker: SafeUrl[] = [];
  listText: TextHandle[] = [];
  dragPosition = { x: 0, y: 0 };
  touchTime = 0;

  private grayscale = '';

  get mobileHorizontal() {
    return localStorage.getItem('orientation') === 'horizontal';
  }

  get grayScale() {
    return this.grayscale;
  }

  get isMobile() {
    if (window.screen.width < 800) {
      return true;
    }
    return false;
  }

  private pTextHandle: TextHandle;

  get textHandle(): TextHandle {
    return this.pTextHandle;
  }

  constructor(private dragDropService: DragDropShareService, private dialog: MatDialog) { }

  deleteAttachment() {
    this.pTextHandle = {} as TextHandle;
    this.dropData.splice(0);
    this.scale = 100;
    this.urlUpload = '';
    this.listSticker.splice(0);
    this.listText.splice(0);
    this.dragPosition = { x: 0, y: 0 };
  }

  drop(event: CdkDragDrop<FileHandle[]>) {
    this.dragDropService.drop(event);
    if (this.dropData[0]) {
      if (this.dropData[0].isSticker) {
        this.listSticker.push(this.dropData[0].url);
      } else if (this.dropData[0].textHandle) {
        this.listText.push(CreateObj.createNewObjText(this.dropData[0].textHandle));
      } else {
        this.urlUpload = this.dropData[0].url;
      }
    }
  }

  zoomIn() {
    this.scale = this.scale + 10;
  }

  zoomOut() {
    this.scale = this.scale - 10;
  }

  getClassBackground() {
    if (this.urlUpload || this.listSticker.length > 0 || this.listText.length > 0 || Globals.preview) {
      return 'base color';
    }
    return 'base image';
  }

  openGallery() {
    if (this.isMobile) {
      if (this.touchTime === 0) {
        this.touchTime = new Date().getTime();
      } else {
        console.log((new Date().getTime()) - this.touchTime);
        if (((new Date().getTime()) - this.touchTime) < 800) {
          const dialogRef = this.dialog.open(ImageGalleryModalComponent, {
            disableClose: true,
            width: '90vw',
            height: '70vh',
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result.isDelete) {
              this.urlUpload = result.strUrl;
            }
          });
          this.touchTime = 0;
        } else {
          this.touchTime = new Date().getTime();
        }
      }
    }
  }

  editImage() {
    const dialogRef = this.dialog.open(EditorPhotoComponent, {
      width: '1024px',
      height: '95%',
      data: {
        url: this.urlUpload,
        grayscale: this.grayscale
      }
    });

    dialogRef.afterClosed().subscribe((gray) => {
      this.grayscale = gray;
    });

  }
}
