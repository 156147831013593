import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'dws-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
})
export class ImageContainerComponent implements AfterViewInit {
  @Input() loadImage = true;
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();
  @ViewChild(MatCheckbox) checkbox: MatCheckbox;
  @ViewChild('image') image: any;

  @Input() imagePath: any;
  @Input() value: any;

  loadedImage: File;

  ngAfterViewInit(): void {
    if (this.loadImage) {
      this.fetchImage().then((blob) => {
        this.image.nativeElement.src = URL.createObjectURL(blob);
        this.loadedImage = new File(
          [blob],
          new Date().toString() + '.' + blob.type.split('/')[1]
        );
      });
    } else {
      this.loadedImage = this.value;
      this.image.nativeElement.src = URL.createObjectURL(this.value);
    }
  }

  upadte(): void {
    this.checkbox.toggle();
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
  }

  async fetchImage(): Promise<Blob> {
    return fetch(this.imagePath).then((res) => res.blob());
  }
}
