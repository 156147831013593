import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-v9',
  templateUrl: './v9.component.html',
  styleUrls: ['./v9.component.scss']
})
export class V9Component extends Canvas {

  constructor() {
    super(V9Component, 9);
  }
}
