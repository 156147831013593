import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { FileHandle } from '../models/file-handle.model';

@Injectable({
  providedIn: 'root'
})
export class DragDropShareService {

  constructor() { }

  drop(event: CdkDragDrop<FileHandle[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(
        event.previousContainer?.data,
        event.container?.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
}
