import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditorPhotoComponent } from './components/editor-photo/editor-photo.component';
import {
  AddEditComponent,
  LayoutComponent,
  ListComponent
} from './components/users';
import { AuthGuard } from './guard/auth.guard';
import { HorizontalComponent } from './pages/layouts/horizontal/horizontal.component';
import { SquareComponent } from './pages/layouts/square/square.component';
import { VerticalComponent } from './pages/layouts/vertical/vertical.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AddressComponent } from './pages/signature/register/address/address.component';
import { DeliveryComponent } from './pages/signature/delivery/delivery.component';
import { LoginComponent } from './pages/signature/login/login.component';
import { PhotoComponent } from './pages/signature/photo/photo.component';
import { RecoverComponent } from './pages/signature/recover/recover.component';
import { RegisterComponent } from './pages/signature/register/register.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { H1Component } from './pages/templates/horizontal/h1/h1/h1.component';
import { H13Component } from './pages/templates/horizontal/h13/h13.component';
import { H13aComponent } from './pages/templates/horizontal/h13a/h13a.component';
import { H4Component } from './pages/templates/horizontal/h4/h4.component';
import { H6Component } from './pages/templates/horizontal/h6/h6.component';
import { H8Component } from './pages/templates/horizontal/h8/h8.component';
import { H8aComponent } from './pages/templates/horizontal/h8a/h8a.component';
import { S1Component } from './pages/templates/square/s1/s1/s1.component';
import { S10Component } from './pages/templates/square/s10/s10.component';
import { S13Component } from './pages/templates/square/s13/s13.component';
import { S4Component } from './pages/templates/square/s4/s4.component';
import { S7Component } from './pages/templates/square/s7/s7.component';
import { S8Component } from './pages/templates/square/s8/s8.component';
import { S9Component } from './pages/templates/square/s9/s9.component';
import { V1Component } from './pages/templates/vertical/v1/v1/v1.component';
import { V13Component } from './pages/templates/vertical/v13/v13.component';
import { V4Component } from './pages/templates/vertical/v4/v4.component';
import { V7Component } from './pages/templates/vertical/v7/v7.component';
import { V8Component } from './pages/templates/vertical/v8/v8.component';
import { V9Component } from './pages/templates/vertical/v9/v9.component';
import { BlackPhotoFramesComponent } from './pages/templates/wallpaper/black-photo-frames/black-photo-frames.component';
import { CollageComponent } from './pages/templates/wallpaper/collage/collage.component';
import { OrthogonalComponent } from './pages/templates/wallpaper/orthogonal/orthogonal.component';
import { ScatteredComponent } from './pages/templates/wallpaper/scattered/scattered.component';
import { WoodPhotoFramesComponent } from './pages/templates/wallpaper/wood-photo-frames/wood-photo-frames.component';
import { WallpaperComponent } from './pages/wallpaper/wallpaper.component';

const tempaltesRoutes = [
  {
    path: 'quadrado',
    component: SquareComponent,
    children: [
      { path: 'qpics_q1_mod01', component: S1Component },
      { path: 'qpics_q4_mod01', component: S4Component },
      { path: 'qpics_q7_mod01', component: S7Component },
      { path: 'qpics_q8_mod01', component: S8Component },
      { path: 'qpics_q9_mod01', component: S9Component },
      { path: 'qpics_q10_mod01', component: S10Component },
      { path: 'qpics_q13_mod01', component: S13Component },
    ],
  },
  {
    path: 'horizontal',
    component: HorizontalComponent,
    children: [
      { path: 'qpics_h1_mod01', component: H1Component },
      { path: 'qpics_h4_mod01', component: H4Component },
      { path: 'qpics_h6_mod01', component: H6Component },
      { path: 'qpics_h8_mod01', component: H8Component },
      { path: 'qpics_h8_mod02', component: H8aComponent },
      { path: 'qpics_h13_mod02', component: H13Component },
      { path: 'qpics_h13_mod01', component: H13aComponent },
    ],
  },
  {
    path: 'vertical',
    component: VerticalComponent,
    children: [
      { path: 'qpics_v1_mod01', component: V1Component },
      { path: 'qpics_v4_mod01', component: V4Component },
      { path: 'qpics_v7_mod01', component: V7Component },
      { path: 'qpics_v8_mod01', component: V8Component },
      { path: 'qpics_v9_mod01', component: V9Component },
      { path: 'qpics_v13_mod01', component: V13Component },
    ],
  },
];

const papelDParede = [
  { path: ':orientation/pp_h_mod01', component: BlackPhotoFramesComponent },
  { path: ':orientation/pp_h_mod02', component: WoodPhotoFramesComponent },
  { path: ':orientation/pp_h_mod03', component: CollageComponent },
  { path: ':orientation/pp_h_mod04', component: OrthogonalComponent },
  { path: ':orientation/pp_h_mod05', component: ScatteredComponent },
  { path: ':orientation/pp_v_mod01', component: BlackPhotoFramesComponent },
  { path: ':orientation/pp_v_mod02', component: WoodPhotoFramesComponent },
  { path: ':orientation/pp_v_mod03', component: CollageComponent },
  { path: ':orientation/pp_v_mod04', component: OrthogonalComponent },
  { path: ':orientation/pp_v_mod05', component: ScatteredComponent },
];

const routes: Routes = [
  { path: '', component: PageNotFoundComponent },

  {
    path: 'dropbox',
    children: [
      {
        path: 'templates',
        children: tempaltesRoutes,
      },
      {
        path: 'papel-de-parede',
        component: WallpaperComponent,
        children: papelDParede,
      },
    ],
  },

  {
    path: 'instagram',
    children: [
      {
        path: 'templates',
        children: tempaltesRoutes,
      },
      {
        path: 'papel-de-parede',
        component: WallpaperComponent,
        children: papelDParede,
      },
    ],
  },

  { path: 'editor', component: EditorPhotoComponent },

  {
    path: 'templates',
    children: tempaltesRoutes,
  },

  {
    path: 'papel-de-parede',
    component: WallpaperComponent,
    children: papelDParede,
  },

  {
    path: 'users',
    component: LayoutComponent,
    children: [
      { path: '', component: ListComponent },
      { path: 'add', component: AddEditComponent },
      { path: 'edit/:id', component: AddEditComponent },
    ],
  },

  {
    path: 'signature',
    component: SignatureComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'recover', component: RecoverComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'register/:email', component: RegisterComponent },
      { path: 'photos', component: PhotoComponent, canActivate: [AuthGuard] },
      { path: 'delivery', component: DeliveryComponent, canActivate: [AuthGuard] },
      { path: 'address', component: AddressComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: 'photos', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
