import { Component, Input, OnInit } from '@angular/core';
import { TemplateService } from '@app/api';
import { OrientationEnum } from '@app/helpers/enumerators/orientation.enum';

@Component({
  selector: 'dws-select-layouts',
  templateUrl: './select-layouts.component.html',
  styleUrls: ['./select-layouts.component.scss']
})
export class SelectLayoutsComponent implements OnInit {

  @Input() orientation: OrientationEnum;

  orientationTemplate = OrientationEnum;

  selectColor: string;

  constructor(private templateService: TemplateService) { }

  ngOnInit(): void {
  }

  clickCells(value: number) {
    this.templateService.getByFolder().subscribe(template => {
      template.cells = value;
      this.templateService.create(template).toPromise();
    });
  }
}
