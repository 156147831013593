import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h6',
  templateUrl: './h6.component.html',
  styleUrls: ['./h6.component.scss']
})
export class H6Component extends Canvas {

  constructor() {
    super(H6Component, 6);
  }

}
