import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h8a',
  templateUrl: './h8a.component.html',
  styleUrls: ['./h8a.component.scss']
})
export class H8aComponent extends Canvas {

  constructor() {
    super(H8aComponent, 8);
  }

}
