<div [ngClass]="getClassBackground()" (click)="openGallery()">
  <div *ngIf="!urlUpload" class="vertical">Clique 2x inserir/trocar imagem</div>
  
  <div *ngIf="isMobile; else desktop">
    <div class="drop-image-mobile" id="{{ connectionId }}" cdkDrag>
      <img *ngIf="urlUpload" [ngClass]="{'m-horizontal': mobileHorizontal, 'm-vertical': !mobileHorizontal}"
        [src]="urlUpload" />
    </div>
  </div>

  <ng-template #desktop>
    <div class="drop-image" cdkDropList id="{{ connectionId }}" [cdkDropListData]="dropData"
      [cdkDragFreeDragPosition]="dragPosition" [cdkDropListConnectedTo]="connectedTo"
      (cdkDropListDropped)="drop($event)" cdkDrag>
      <img *ngIf="urlUpload" [src]="urlUpload"
        [ngStyle]="{ transform: 'scale(' + scale / 100 + ')', filter: grayScale }" />
    </div>

    <div class="drop-sticker" *ngFor="let url of listSticker" cdkDrag>
      <img [src]="url" />
    </div>

    <div class="drop-text" *ngFor="let item of listText" cdkDrag>
      <p [ngStyle]="item.style">
        {{ item.text }}
      </p>
    </div>
  </ng-template>
</div>

<div class="hide">
  <div *ngIf="urlUpload || listSticker.length > 0 || listText.length > 0">
    <button class="btn clear" mat-icon-button (click)="deleteAttachment()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div *ngIf="urlUpload">
    <button class="btn zoom-in" mat-icon-button (click)="zoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button class="btn zoom-out" mat-icon-button (click)="zoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </button>

    <button class="btn edit" mat-icon-button (click)="editImage()">
      <mat-icon>format_color_fill</mat-icon>
    </button>
  </div>
</div>