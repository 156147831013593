import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends ServiceBase {

  private uri = `${environment.urlBackend}/orderitem`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  create(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sku', localStorage.getItem('sku') || '');
    formData.append('tamanho', localStorage.getItem('tamanho') || '');

    return this.http.post<any>(`${this.uri}/model/`, formData, this.createOptions())
      .pipe(tap((res: any) => {
        return res;
      }), catchError(this.handleError));
  }
}
