import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/services';
import { ThemeService, ThemeType } from '@app/services/theme.service';

@Component({
  selector: 'dws-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  theme: string;
  isDarkTheme = false;
  projectName = '';
  title = 'APLICATIVO PARA CRIAÇÃO E EDIÇÃO DE ARTE PELO CLIENTE DA LIFEWALL';

  loggedIn = false;

  get clientName() {
    return `${this.authService.currentUserValue}`;
  }

  constructor(
    private themeService: ThemeService,
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.theme = this.themeService.theme;
    this.isDarkTheme = this.theme === 'Dark';

    if (location.href.includes('signature')) {
      this.title = 'Plano de Assinatura de Fotografias para Quadros';
    }
    this.authService.logged$.subscribe(() => {
      this.loggedIn = this.authService.isLogged;
    });
  }

  onAlternateTheme() {
    this.themeService.theme === ThemeType.Light ? this.themeService.addThemeDark() : this.themeService.addThemeLight();
    this.theme = this.themeService.theme;
    this.isDarkTheme = !this.isDarkTheme;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/signature/login']);
  }

  update() {
    this.router.navigate([`/signature/register/editar`]);
  }

  returnLifewall() {
    this.router.navigate(['https://lifewall.art/']);
  }

}
