<div class="title">
  <h1>Quadro Multi Fotos</h1>
</div>

<section class="content">
  <section class="container">
    <h2>Material:</h2>
    <hr />
    <div class="box-1" (click)="clickMaterial('Tela')" [ngClass]="{material: material === 'Tela'}">
      <div class="square"></div>
      <p>Tela</p>
    </div>
    <div class="box-1" (click)="clickMaterial('Papel Fotográfico')"
      [ngClass]="{material: material === 'Papel Fotográfico'}">
      <div class="square"></div>
      <p>Papel Fotográfico</p>
    </div>
  </section>

  <section class="container">
    <h2>Orientação:</h2>
    <hr />
    <div class="box-2" (click)="clickOrientation(orientationTemplate.square)"
      [ngClass]="{orientation: orientationEnum === orientationTemplate.square}">
      <div class="square"></div>
      <p>Quadrado</p>
    </div>
    <div class="box-2" (click)="clickOrientation(orientationTemplate.vertical)"
      [ngClass]="{orientation: orientationEnum === orientationTemplate.vertical}">
      <div class="vertical"></div>
      <p>Vertical</p>
    </div>
    <div class="box-2" (click)="clickOrientation(orientationTemplate.horizontal)"
      [ngClass]="{orientation: orientationEnum === orientationTemplate.horizontal}">
      <div class="horizontal"></div>
      <p>Horizontal</p>
    </div>
  </section>

  <section class="container">
    <h2>Dimensão:</h2>
    <hr />
    <div class="dimension" (click)="clickDimension(sizeEnum.small)"
      [ngClass]="{dimensionSelected: sizeTemplate === sizeEnum.small}">
      <div class="size p">P</div>
      <p>Altura: {{ orientation.getSizeValue.small.height }} cm</p>
      <p>Largura: {{ orientation.getSizeValue.small.wight }} cm</p>
    </div>
    <div class="dimension" (click)="clickDimension(sizeEnum.medium)"
      [ngClass]="{dimensionSelected: sizeTemplate === sizeEnum.medium}">
      <div class="size m">M</div>
      <p>Altura: {{ orientation.getSizeValue.medium.height }} cm</p>
      <p>Largura: {{ orientation.getSizeValue.medium.wight }} cm</p>
    </div>
    <div class="dimension" (click)="clickDimension(sizeEnum.big)"
      [ngClass]="{dimensionSelected: sizeTemplate === sizeEnum.big}">
      <div class="size g">G</div>
      <p>Altura: {{ orientation.getSizeValue.big.height }} cm</p>
      <p>Largura: {{ orientation.getSizeValue.big.wight }} cm</p>
    </div>
  </section>

  <section class="container">
    <h2>Template {{orientationEnum}}:</h2>
    <hr />
    <dws-select-layouts [orientation]="orientationEnum"></dws-select-layouts>
  </section>

  <section class="container">
    <h2>3. Definir borda:</h2>
    <hr />
    <div class="form-group">
      <label for="color-input">Tamanho e cor</label>
      <div class="input-group">
        <input class="form-control" [value]="borderSize" type="number" min="0" max="10"
          (change)="changeBorderSize($event)" />
        <input class="form-control" type="color" value="{{borderColor}}" [(ngModel)]="borderColor"
          (change)="changeBorderColor($event)" />
      </div>
    </div>
  </section>

</section>
