import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Result } from '@app/models';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GalleryService extends ServiceBase {

  private uri = `${environment.urlBackend}/gallery/images`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  getAll() {
    return this.http.get<Result>(this.uri).pipe(catchError(this.handleError));
  }

  getByType(type: string) {
    return this.http.get<Result>(`${this.uri}/${type}`).pipe(catchError(this.handleError));
  }
}
