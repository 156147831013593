// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlFrontend: 'https://lifewall.art',
  urlBackend: 'http://161.97.163.176:8000',
  clientIdFacebook: '237537547704668',
  clientSecretInstagram: 'b23fa9ee3e4b3f37fd0be42e86b2c66d',
  clientIdInstagram: '4592053774155322',
  uriRedirectInstagram: `https://apistage.lifewall.art/instagram${location.hash.split('?')[0].replace('#', '')}`,
  uriRedirectDrobpbox: `${location.origin}/dropbox${location.pathname}`,
  clientSecretDropbox: 'qhgueitz1scsmh5',
  clientIdDropbox: 'e09b6yefek8uqu5',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
