import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChildren,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '@app/api';
import { FileHandle } from '@app/models';
import { of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseWrapper } from './base-wrapper/base-wrapper.component';

@Component({
  selector: 'dws-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements AfterViewInit {
  @ViewChildren('wrapper') wrapper: BaseWrapper[];
  selectedIndex = 0;
  upload = false;
  signatute = false;

  constructor(
    private dialogRef: MatDialogRef<FileHandle[]>,
    private imageService: UploadService,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    if (location.href.includes(`instagram`)) {
      this.selectedIndex = 2;
      this.ref.detectChanges();
    }
    this.signatute = location.href.includes('signature');
  }

  getData(): void {
    const files: any = [];

    this.upload = true;

    this.wrapper
      .filter((item) => item.hasSelectedImages)
      .forEach((item) =>
        item.imageComponents
          .filter(i => i.selected)
          .map((imgCmpt) => imgCmpt.loadedImage)
          .forEach((file) => {
            files.push(
              this.imageService.create(file, this.signatute).pipe(
                map((res: any) => ({
                  _id: res.code ? res.code.toString() : res._id,
                  file,
                  url: this.sanitizer.bypassSecurityTrustUrl(`${res.imageUrl}`),
                  selected: false,
                  strUrl: `${res.imageUrl}`,
                })),
                catchError((err) => of(null))
              )
            );
          })
      );

    zip(...files).subscribe((newFiles) => {
      this.close(newFiles.filter((item) => item !== null));
      this.upload = false;
    });
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  get invalid(): boolean {
    if (!this.wrapper) {
      return true;
    }
    return this.wrapper.filter((item) => item.hasSelectedImages).length === 0;
  }
}
