import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  BaseWrapper,
  BaseWrapperEnum,
} from '../base-wrapper/base-wrapper.component';
import { FacebookService } from '../services/facebook.service';

@Component({
  selector: 'dws-facebook-wrapper',
  templateUrl: './facebook-wrapper.component.html',
  styleUrls: ['./facebook-wrapper.component.scss'],
})
export class FacebookWrapperComponent extends BaseWrapper implements OnInit {
  images: Array<{ minImage: any; value: any }> = [];
  status = false;
  componentName = BaseWrapperEnum.FACEBOOK;
  albumsData: Array<{ albumName: string; images: any[] }> = [];

  constructor(
    private ref: ChangeDetectorRef,
    private facebookService: FacebookService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchImages();
  }

  login() {
    this.facebookService.login().then(() => {
      this.fetchImages();
    });
  }

  fetchImages(): void {
    this.facebookService.images().then((data) => {
      if (data.length > 0) {
        data.forEach((item: any) => {
          const images: any[] = [];

          item.photos?.data.forEach((photo: any) => {
            const minImage = photo.images.reduce((aux: any, current: any) => {
              return aux?.height > current?.height &&
                aux?.width > current?.width ? aux : current;
            });
            images.push({ minImage, value: photo });
          });

          this.albumsData.push({
            albumName: item.name,
            images,
          });
        });

        this.status = true;
        this.ref.detectChanges();
      }
    });
  }
}
