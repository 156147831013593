<section class="content">
  <section class="container">
    <div class="title">
      <h1>Adicione um elemento</h1>
      <hr />
    </div>
    <div class="row center">
      <div class="col">
        <div class="btn-gallery" (click)="clickType('photo')" [ngClass]="{selected: selectedGallery === 'photo'}">
          <div class="btn-icon">
            <div class="icon gallery"></div>
          </div>
          <p>Foto</p>
        </div>
      </div>
      <div class="col">
        <div class="btn-gallery" (click)="clickType('illustration')"
          [ngClass]="{selected: selectedGallery === 'illustration'}">
          <div class="btn-icon">
            <div class="icon illustration"></div>
          </div>
          <p>Ilustração</p>
        </div>
      </div>
    </div>
    <div class="row center">
      <div class="col">
        <div class="btn-gallery" (click)="clickType('sampler')" [ngClass]="{selected: selectedGallery === 'sampler'}">
          <div class="btn-icon">
            <div class="icon sampler"></div>
          </div>
          <p>Padrão</p>
        </div>
      </div>
      <div class="col">
        <div class="btn-gallery" (click)="clickType('sticker')" [ngClass]="{selected: selectedGallery === 'sticker'}">
          <div class="btn-icon">
            <div class="icon sticker"></div>
          </div>
          <p>Sticker</p>
        </div>
      </div>
    </div>
  </section>

  <div class="space"></div>

  <section class="container">
    <h2>{{titleGallery}}</h2>
    <hr />
    <div class="row" cdkDropList id="dropGallery" [cdkDropListData]="files" [cdkDropListConnectedTo]="connectedTo"
      cdkDropListSortingDisabled>
      <div class="col-sm">

        <div fxFill fxLayout="column" fxLayoutAlign="space-between">
          <div fxLayout="row wrap">
            <div class="img-wrap" *ngFor="let photo of files; let i = index">
              <img [src]="photo.url" cdkDrag />
            </div>
          </div>

          <mat-paginator [length]="filesPresentation.length" [pageSize]="size" [pageIndex]="page" [pageSizeOptions]="[9]"
            [hidePageSize]="true" (page)="getData($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </section>
</section>
