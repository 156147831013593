import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutBase } from '../layout-base';

@Component({
  selector: 'dws-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})
export class HorizontalComponent extends LayoutBase {

  constructor(route: ActivatedRoute, location: Location) {
    super(route, location);
  }
}
