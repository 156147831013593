<div class="dialog">

  <div class="divImg">
    <h5>Pré visualizar Produto</h5>
    <hr />
    <img [src]="data.url" class="img" />
  </div>

  <div class="divDetails">
    <div class="card">
      <div class="card-header">
        <h5>Detalhes do produto</h5>
      </div>
      <div class="card-body">
        <form class="example-form">
          <mat-form-field>
            <mat-label>Material:</mat-label>
            <input matInput disabled [value]="data.template.material">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tamanho (largura x altura):</mat-label>
            <input matInput disabled [value]="data.template.tamanho">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Número de fotos:</mat-label>
            <input matInput disabled
              value="{{data.template.qtdFotos}} {{data.template.qtdFotos <= 1 ? 'Foto' : 'Fotos'}}">
          </mat-form-field>
          <mat-form-field style="width: 50%;">
            <mat-label>Moldura: </mat-label>
            <input matInput disabled style="height: 24px;" [value]="getTamanhoBorda()">
          </mat-form-field>
          <mat-form-field style="width: 50%;">
            <mat-label>Cor: </mat-label>
            <input matInput disabled type="color" [value]="data.template.corBorda">
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>

  <mat-toolbar class="footer">
    <button type="button" class="btn btn-seize" (click)="onClose()">
      Voltar ao editor
    </button>
    <span style="flex: auto;"></span>
    <button type="button" class="btn btn-seize red" (click)="onSave()">
      <i class="bi bi-cart-plus"></i>Adicionar Carrinho
    </button>
  </mat-toolbar>
</div>