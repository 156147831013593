import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '@app/models/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  obterAtributo(sku: string, orientation: string, largura: number): Observable<any> {
    return this.getProducts().pipe(map((produtos: Product[]) => {
      return produtos
        .find(p => p.sku === sku && p.orientacao.toLowerCase() === orientation.toLowerCase())?.atributos
        .find(a => a.largura === largura);
    }));
  }

  obterMinimo(sku: string, orientation: string, largura: number): Observable<number> {
    return this.getProducts().pipe(map((produtos: Product[]) => {
      const produto = produtos
        .find(p => p.sku === sku && p.orientacao.toLowerCase() === orientation.toLowerCase())?.atributos
        .find(a => a.largura === largura);

      return produto ? produto.minimo : 0;
    }));
  }

  obterMaximo(sku: string, orientation: string, largura: number) {
    return this.getProducts().pipe(map((produtos: Product[]) => {
      const produto = produtos
        .find(p => p.sku === sku && p.orientacao.toLowerCase() === orientation.toLowerCase())?.atributos
        .find(a => a.largura === largura);

      return produto ? produto.maximo : 0;
    }));
  }

  getProducts(): Observable<any> {
    return this.http.get('../assets/json/produtos.json');
  }
}
