<div class="login">
    <div class="card shadow-lg">
        <div class="card-body">
            <mat-tab-group>
                <mat-tab label="Cliente">
                    <dws-client></dws-client>
                </mat-tab>
                <mat-tab label="Endereço" *ngIf="isLogged">
                    <dws-address></dws-address>
                </mat-tab>
                <mat-tab label="Funcionamento" *ngIf="isLogged">
                    <dws-profile></dws-profile>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>