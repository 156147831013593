import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ServiceBase {

  private uri = `${environment.urlBackend}/token`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  save(project: ProjectResult): Observable<ProjectResult> {
    return this.http.post<ProjectResult>(`${this.uri}/sendsms`, project, this.createOptions())
      .pipe(tap(res => res), catchError(this.handleError));
  }

  toRecover(project: ProjectResult): Observable<ProjectResult> {
    return this.http.post<ProjectResult>(`${this.uri}/get`, project)
      .pipe(tap(res => res), catchError(this.handleError));
  }
}

export interface ProjectResult {
  telefone: string;
  codigosms?: string;
  folderToken?: string;
}
