import { Component, OnInit } from '@angular/core';
import { Canvas } from '@app/utils/canvas';

@Component({
  selector: 'dws-v1',
  templateUrl: './v1.component.html',
  styleUrls: ['./v1.component.scss']
})
export class V1Component extends Canvas {

  constructor() {
    super(V1Component, 1);
  }

}
