import { Component, ViewChildren } from '@angular/core';
import { ImageContainerComponent } from '../image-container/image-container.component';

export const enum BaseWrapperEnum {
  DEVICE = 'DEVICE',
  FACEBOOK = 'FACEBOOK',
  INSTRAGRAM = 'INSTRAGRAM',
}

@Component({
  template: '',
})
export class BaseWrapper {
  @ViewChildren(ImageContainerComponent)
  imageComponents: ImageContainerComponent[];
  name: BaseWrapperEnum;

  get data(): { name: BaseWrapperEnum; images: any } {
    return {
      name: this.name,
      images: this.imageComponents.filter((item) => item.selected),
    };
  }

  get hasSelectedImages(): boolean {
    if (!this.imageComponents) return false;
    if (this.imageComponents.find((item) => item.selected)) {
      return true;
    } else {
      return false;
    }
  }

  updateSelection(): void {
    this.imageComponents.forEach((item) => {
      if (!item.selected) {
        item.upadte();
      }
    });
  }
}
