import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-s7',
  templateUrl: './s7.component.html',
  styleUrls: ['./s7.component.scss']
})
export class S7Component extends Canvas {

  constructor() {
    super(S7Component, 7);
  }

}
