import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '@app/api';
import { FileHandle } from '@app/models/file-handle.model';
import { Canvas } from '@app/utils/canvas';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';

@Component({
  selector: 'dws-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  files: FileHandle[] = [];

  imageUrl: string;
  imageFile: File;
  borderSize = '4';
  borderColor = '#FFFFFF';

  private indexEditable: number;

  get connectedTo(): string[] {
    return [...Canvas.listIdConnection];
  }

  get filesSelected(): boolean {
    return this.files.filter((x) => x.selected).length > 0;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private imageService: UploadService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getImages();

    if (location.href.includes(`instagram`)) {
      this.openDialogUpload();
    }
  }

  openDialogUpload() {
    const dialog = this.dialog.open(UploadDialogComponent, {
      maxWidth: '98vw',
      height: window.innerWidth < 420 ? '90vh' : '680px',
      width: window.innerWidth < 420 ? '93vw' : '960px',
      panelClass: 'upload-modal-container',
      disableClose: true,
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((files: FileHandle[]) => {
        if (files && files.length > 0) {
          this.files = [...this.files, ...files];
        }
      });
  }

  getImages() {
    this.spinner.show();
    this.imageService
      .getByFolder()
      .subscribe((res) => {
        for (const img of res.results) {
          const imageUrl = `${img.imageUrl}`;
          const imgFile = new File([imageUrl], img.imageName, {
            type: 'image/jpeg',
          });
          const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
          const file: FileHandle = {
            _id: img.code ? img.code.toString() : img._id,
            file: imgFile,
            url: urlSafe,
            selected: false,
            strUrl: imageUrl,
          };
          this.files.push(file);
        }
      })
      .add(() => this.spinner.hide());
  }

  filesInput(evt: Event) {
    const files = (evt.target as HTMLInputElement).files as FileList;
    this.filesDropped(files);
  }

  filesDropped(files: FileList) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        this.spinner.show();
        this.imageService
          .create(files[key])
          .subscribe((res) => {
            const imageUrl = `${res.imageUrl}`;
            const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
            const fileHandle: FileHandle = {
              _id: res.code ? res.code.toString() : res._id,
              file: files[key],
              url: urlSafe,
              selected: false,
              strUrl: imageUrl,
            };
            this.files.push(fileHandle);
          })
          .add(() => this.spinner.hide());
      }
    }
  }

  updateImage(event: CroppedEvent) {
    this.spinner.show();
    const file = event.file || ({} as File);
    const id = this.files[this.indexEditable]._id || '';
    this.imageService.update(file, id).subscribe({
      next: (resp) => {
        const imagem = this.files[this.indexEditable];
        const imageUrl = `${resp.imageUrl}`;
        const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        const fileHandle: FileHandle = {
          _id: imagem._id,
          file: event.file,
          url: urlSafe,
          selected: false,
          strUrl: imageUrl,
        };
        this.files.splice(this.indexEditable, 1, fileHandle);
        this.spinner.hide();
      },
      error: (err) => console.log(err),
      complete: () => this.spinner.hide(),
    });
  }

  deleteImage(index: number) {
    const id = this.files[index]._id || '';
    this.imageService.delete(id).subscribe(() => {
      this.files.splice(index, 1);
    });
  }

  selectAll(checked: boolean) {
    for (const file of this.files) {
      this.selectImage({ checked, file });
    }
  }

  selectImage(event: any) {
    const index: number = this.files.indexOf(event.file);
    this.files[index].selected = event.checked;
  }

  editImage(event: any) {
    this.indexEditable = event.index;
    this.imageUrl = event.file.url;
    this.imageFile = event.file.file;
  }

  excludeImageSelectedOrAll() {
    const filesSelected = this.filesSelected
      ? this.files.filter((x) => x.selected)
      : this.files;
    this.deleteFiles(filesSelected);
  }

  changeBorderSize(event: Event) {
    this.borderSize = (event.target as HTMLSelectElement).value;
    const sizeBorder = `${Number(this.borderSize) * 0.1}vw`;
    document.documentElement.style.setProperty(
      '--cells-border-size',
      sizeBorder
    );
  }

  changeBorderColor(event: Event) {
    this.borderColor = (event.target as HTMLSelectElement).value;
    document.documentElement.style.setProperty(
      '--cells-border-color',
      this.borderColor
    );
  }

  private deleteFiles(files: FileHandle[]) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const file = this.files[key];
        this.imageService.delete(file._id || '').subscribe(() => {
          const index = this.files.indexOf(file);
          this.files.splice(index, 1);
        });
      }
    }
  }
}
