import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export abstract class LayoutBase {

    sku: string;

    scale = 100;

    get orientacao() {
        return localStorage.getItem('orientation')?.toLowerCase();
    }

    get isWallpaper() {
        return localStorage.getItem('produto')?.trim() === 'papel-de-parede';
    }

    constructor(route: ActivatedRoute, location: Location) {
        if (route.snapshot.params.orientation) {
            localStorage.setItem('orientation', route.snapshot.params.orientation);
        } else {
            localStorage.removeItem('orientation');
        }
        route.queryParams.subscribe(params => {
            if (params.tamanho) {
                localStorage.setItem('tamanho', params.tamanho);
            }

            if (params.comprimento) {
                localStorage.setItem('comprimento', this.convertCentimeters(params.comprimento));
                localStorage.setItem('attribute_comprimento', params.comprimento);
            }

            if (params.largura) {
                localStorage.setItem('largura', this.convertCentimeters(params.largura));
                localStorage.setItem('attribute_largura', params.largura);
            }

            if (params.altura) {
                localStorage.setItem('altura', this.convertCentimeters(params.altura));
            }

            this.sku = location.path().split('?')[0].split('/').pop() || '';
            localStorage.setItem('sku', this.sku);
        });
    }

    zoomIn() {
        this.scale = this.scale + 10;
    }

    zoomOut() {
        this.scale = this.scale - 10;
    }

    private convertCentimeters(tamanho: string) {
        const value = Number(tamanho.replace(/[a-zA-Z]+/g, '').replace(',', '.').trim()) * 100;
        return value.toString();
    }
}
