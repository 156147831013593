import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

export enum ThemeType {
  Light = 'Light',
  Dark = 'Dark'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private overlayContainer: OverlayContainer) {
    this.addTheme(this.theme);
  }

  get theme() {
    const type = localStorage.getItem('theme') || 'Light';
    return ThemeType[type as keyof typeof ThemeType];
  }

  set theme(theme: ThemeType) {
    localStorage.setItem('theme', theme);
  }

  private addTheme(theme: ThemeType): void {
    const overlayClassList = this.overlayContainer.getContainerElement().classList;
    const themesRemove = Array.from(overlayClassList).filter((i: string) => i.includes('-theme'));

    if (themesRemove.length) {
      overlayClassList.remove(...themesRemove);
      document.body.classList.remove(...themesRemove);
    }

    overlayClassList.add(`${theme.toLowerCase()}-theme`);
    document.body.classList.add(`${theme.toLowerCase()}-theme`);

    this.theme = theme;
  }

  addThemeLight = () => this.addTheme(ThemeType.Light);

  addThemeDark = () => this.addTheme(ThemeType.Dark);
}
