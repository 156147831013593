import { UploadDialogComponent } from '@app/components/upload-dialog/upload-dialog.component';
import { UploadService } from '@app/api';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileHandle } from '@app/models';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dws-image-gallery-modal',
  templateUrl: './image-gallery-modal.component.html',
  styleUrls: ['./image-gallery-modal.component.scss']
})
export class ImageGalleryModalComponent implements OnInit {

  files: FileHandle[] = [];

  imageUrl: string;
  imageFile: File;
  borderSize = '4';
  borderColor = '#FFFFFF';

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ImageGalleryModalComponent>,
    private imageService: UploadService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  get filesSelected(): boolean {
    return this.files.filter((x) => x.selected).length > 0;
  }

  ngOnInit(): void {
    this.getImages();
  }

  getImages() {
    this.spinner.show();
    this.imageService
      .getByFolder()
      .subscribe((res) => {
        for (const img of res.results) {
          const imageUrl = `${img.imageUrl}`;
          const imgFile = new File([imageUrl], img.imageName, {
            type: 'image/jpeg',
          });
          const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
          const file: FileHandle = {
            _id: img.code ? img.code.toString() : img._id,
            file: imgFile,
            url: urlSafe,
            selected: false,
            strUrl: imageUrl,
          };
          this.files.push(file);
        }
      })
      .add(() => this.spinner.hide());
  }

  openDialogUpload() {
    const dialog = this.dialog.open(UploadDialogComponent, {
      maxWidth: '98vw',
      height: window.innerWidth < 420 ? '90vh' : '680px',
      width: window.innerWidth < 420 ? '93vw' : '960px',
      panelClass: 'upload-modal-container',
      disableClose: true,
    });

    dialog.afterClosed().pipe(first()).subscribe((files: FileHandle[]) => {
      if (files && files.length > 0) {
        this.files = [...this.files, ...files];
      }
    });
  }

  filesInput(evt: Event) {
    const files = (evt.target as HTMLInputElement).files as FileList;
    this.filesDropped(files);
  }

  filesDropped(files: FileList) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        this.spinner.show();
        this.imageService
          .create(files[key])
          .subscribe((res) => {
            const imageUrl = `${res.imageUrl}`;
            const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
            const fileHandle: FileHandle = {
              _id: res.code ? res.code.toString() : res._id,
              file: files[key],
              url: urlSafe,
              selected: false,
              strUrl: imageUrl,
            };
            this.files.push(fileHandle);
          })
          .add(() => this.spinner.hide());
      }
    }
  }

  onNoClick(isDelete: boolean): void {
    this.dialogRef.close({strUrl: '', isDelete});
  }

  excludeImageSelectedOrAll() {
    const filesSelected = this.filesSelected
      ? this.files.filter((x) => x.selected)
      : this.files;
    this.deleteFiles(filesSelected);
  }

  selectAll(checked: boolean) {
    for (const file of this.files) {
      this.selectImage({ checked, file });
    }
  }

  selectImage(event: any) {
    this.dialogRef.close({strUrl: event.file.strUrl, isDelete: true});
  }

  deleteImage(index: number) {
    const id = this.files[index]._id || '';
    this.imageService.delete(id).subscribe(() => {
      this.files.splice(index, 1);
    });
  }

  private deleteFiles(files: FileHandle[]) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const file = this.files[key];
        this.imageService.delete(file._id || '').subscribe(() => {
          const index = this.files.indexOf(file);
          this.files.splice(index, 1);
        });
      }
    }
  }
}
