import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h13a',
  templateUrl: './h13a.component.html',
  styleUrls: ['./h13a.component.scss']
})
export class H13aComponent extends Canvas {

  constructor() {
    super(H13aComponent, 13);
   }

}
