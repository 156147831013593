import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutService } from '@app/api/frontend/checkout.service';
import { ValidationCodeDialogComponent } from '@app/components/validation-code-dialog/validation-code-dialog.component';
import { VideoDialogComponent } from '@app/components/video-dialog/video-dialog.component';
import { Dimension, User } from '@app/models';
import { Location } from '@angular/common';
import { Globals } from '@app/utils/globals';
import { AuthService } from '@app/services';

@Component({
  selector: 'dws-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  template = {} as Template;
  dimensionSize = {} as Dimension;
  user: User;
  path: string;

  get isLogged() {
    return this.authService.isLogged;
  }

  get isMobile() {
    return window.screen.width < 800;
  }

  get textButton() {
    if (this.isSignature) {
      return 'Assinar';
    }

    if (this.path === 'papel-de-parede') {
      return Globals.isMobile ? 'Enviar Carrinho' : '5. Enviar Carrinho';
    }

    return Globals.isMobile ? 'Finalizar Compra' : '4. Finalizar Compra';
  }

  get disabledCheckout() {
    const token = localStorage.getItem('folder-token') || undefined;
    return token ? false : true;
  }

  get isWallpaper() {
    return this.path === 'papel-de-parede';
  }

  get isSignature() {
    return this.path === 'signature';
  }

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private checkoutService: CheckoutService,
    location: Location,
    ) {
    this.path = location.path().split('/')[1];
  }

  ngOnInit(): void {
  }

  openVideo(): void {
    this.dialog.open(VideoDialogComponent, {
      data: {
        title: 'Video',
        text: false
      },
      width: '600px',
    });
  }

  openDicas(): void {
    this.dialog.open(VideoDialogComponent, {
      data: {
        title: 'Dicas para melhor aproveitamento do Aplicativo de Criação e Edição de Arte da LIFEWALL',
        text: true
      },
      width: '600px',
    });
  }

  openDialog(type: string): void {
    const dialogRef = this.dialog.open(ValidationCodeDialogComponent, {
      width: '25vw',
      minWidth: '200px',
      data: type,
    });

    dialogRef.afterClosed().subscribe(reload => {
      if (reload) {
        window.location.reload();
      }
    });
  }

  gerarPng() {
    Globals.preview = true;
    setTimeout(() => {
      if (this.path === 'papel-de-parede' || this.isSignature) {
        this.checkoutService.wallpaperCheckout();
      } else {
        const node = document.getElementById('node') || {} as HTMLElement;
        Globals.loadingText = 'Aguarde um pouco, estamos gerando a sua imagem em alta qualidade!';
        this.checkoutService.createSvgCheckout(node);
      }
    }, 5);
  }

  novoProjeto() {
    Globals.urlWallpaper = '';
    localStorage.removeItem('wasGenerated');
  }

  private getInformationTemplate() {
    const sku = localStorage.getItem('sku') || '';
    const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--cells-border-color').trim();
    return {
      tamanho: localStorage.getItem('tamanho'),
      qtdFotos: Globals.getNumberPhotosBySku(sku),
      tamanhoBorda: getComputedStyle(document.documentElement).getPropertyValue('--cells-border-size').trim(),
      corBorda: borderColor.toUpperCase() === '#FFF' ? '#FFFFFF' : borderColor
    };
  }
}
