<div class="dialog">

  <div class="divImg">
    <h5>Editar imagem</h5>
    <hr />
    <img [src]="urlImage" [ngStyle]="{filter: filter}" class="img" />
  </div>

  <div class="divDetails">
    <div class="card">
      <div class="card-header">
        <h5>Filtros</h5>
      </div>
      <div class="card-body">
        <form>
          <mat-label>Tons de Cinza: </mat-label>
          <mat-slider id="gs" name="gs" min="" max="100" value="0" (input)="makeGrayscale($event)"></mat-slider>
          <br />
          <mat-label>Ofuscar: </mat-label>
          <mat-slider id="blur" name="blur" min="0" max="" value="0" (input)="makeBlur($event)"></mat-slider>
          <br />
          <mat-label>Matriz: </mat-label>
          <mat-slider id="hue-rotate" name="hue-rotate" min="0" max="" value="0" (input)="makeHuerotate($event)"></mat-slider>
          <br />
          <mat-label>Sépia: </mat-label>
          <mat-slider id="sepia" name="sepia" min="0" max="" value="0" (input)="makeSepia($event)"></mat-slider>
          <br />
          <mat-label>Brilho: </mat-label>
          <mat-slider id="brightness" name="brightness" min="0" max="300" value="100" (input)="makeBrightness($event)"></mat-slider>
          <br />
          <mat-label>Contraste: </mat-label>
          <mat-slider id="contrast" name="contrast" min="0" max="300" value="100" (input)="makeContrast($event)"></mat-slider>
          <br />
          <mat-label>Inverter: </mat-label>
          <mat-slider id="invert" name="invert" min="0" max="100" value="0" (input)="makeInvert($event)"></mat-slider>
          <br />
          <mat-label>Opacidade: </mat-label>
          <mat-slider id="opacity" name="opacity" min="0" max="100" value="100" (input)="makeOpacity($event)"></mat-slider>
          <br />
          <mat-label>Saturação: </mat-label>
          <mat-slider id="saturate" name="saturate" min="0" max="300" value="100" (input)="makeSaturate($event)"></mat-slider>
        </form>
      </div>
    </div>
  </div>

  <mat-toolbar class="footer">
    <button type="button" class="btn btn-seize" (click)="restore()">
      Restaurar
    </button>
    <span style="flex: auto;"></span>
    <button type="button" class="btn btn-seize red" (click)="save()">
      Alterar
    </button>
  </mat-toolbar>
</div>
