import { Component, OnInit } from '@angular/core';
import { TemplateService } from '@app/api';
import { OrientationEnum, SizeEnum } from '@app/helpers/enumerators';
import { Template } from '@app/models';
import { OrientationService } from '@app/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dws-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss']
})
export class ProductConfigurationComponent implements OnInit {

  material: string;
  price: string;

  orientationTemplate = OrientationEnum;
  sizeEnum = SizeEnum;

  orientationEnum: OrientationEnum;
  sizeTemplate: SizeEnum;
  borderSize: string;
  borderColor: string;

  constructor(public orientation: OrientationService, private templateService: TemplateService) { }

  ngOnInit(): void {
    this.init();
  }

  clickMaterial(value: string) {
    this.init().subscribe(templateCurrent => {
      templateCurrent.material = value;
      this.templateService.create(templateCurrent).subscribe(template => {
        this.material = template.material;
      });
    });
  }

  clickOrientation(value: OrientationEnum) {
    this.init().subscribe(templateCurrent => {
      templateCurrent.orientation = value;
      this.templateService.create(templateCurrent).subscribe(template => {
        this.orientation.setSizeValue = (template.orientation as OrientationEnum);
        this.orientationEnum = (template.orientation as OrientationEnum);
      });
    });
  }

  clickDimension(value: SizeEnum) {
    this.init().subscribe(templateCurrent => {
      templateCurrent.dimension = value;
      this.templateService.create(templateCurrent).subscribe(template => {
        this.sizeTemplate = (template.dimension as SizeEnum);
      });
    });
  }

  changeBorderSize(event: Event) {
    this.init().subscribe(templateCurrent => {
      templateCurrent.borderSize = (event.target as HTMLSelectElement).value;
      this.templateService.create(templateCurrent).subscribe(template => {
        this.updateBorderSize(template.borderSize);
      });
    });
  }

  changeBorderColor(event: Event) {
    this.init().subscribe(template => {
      template.borderColor = (event.target as HTMLSelectElement).value;
      this.templateService.create(template).subscribe(res => {
        this.updateBorderColor(res.borderColor);
      });
    });
  }

  init(): Observable<Template> {
    return this.templateService.getByFolder().pipe((map(template => {
      if (template) {
        this.material = template.material;
        this.orientation.setSizeValue = (template.orientation as OrientationEnum);
        this.orientationEnum = (template.orientation as OrientationEnum);
        this.sizeTemplate = (template.dimension as SizeEnum);
        this.borderColor = template.borderColor || '#ffffff';
        this.borderSize = template.borderSize || '4';
        this.updateBorderColor(this.borderColor);
        this.updateBorderSize(this.borderSize);
      }
      return template;
    })));
  }

  private updateBorderColor(color: string) {
    document.documentElement.style.setProperty('--cells-border-color', color);
  }

  private updateBorderSize(size: string) {
    const sizeBorder = `${Number(size) * 0.1}vw`;
    document.documentElement.style.setProperty('--cells-border-size', sizeBorder);
  }

}
