import { CellCanvas } from '../models/cell-canvas.model';

export class Canvas {

    constructor(component: any, qtdCells: number) {
        this.nameComponent = component.name;
        this.initializeCellCanvas();
        Canvas.lista = this.createListIdConnection(qtdCells);
    }

    static get listIdConnection() {
        return Canvas.lista;
    }

    get gutterValue() {
        return document.documentElement.style.getPropertyValue('--cells-border-size') || '0.4vw';
    }

    private static lista: Array<string> = [];
    private nameComponent: string;

    model: CellCanvas;

    static idConnectionList(component: any, qtdCells: number): Array<string> {
        const canvas = new Canvas(component, qtdCells);
        const listCanvas = new Array<string>();
        for (let index = 0; index < qtdCells; index++) {
            listCanvas.push(canvas.generateIdConnection(index));
        }
        return listCanvas;
    }

    createListIdConnection(qtdCells: number): Array<string> {
        const listCanvas = new Array<string>();
        for (let index = 0; index < qtdCells; index++) {
            listCanvas.push(this.generateIdConnection(index));
        }
        return listCanvas;
    }

    generateIdConnection(index: number): string {
        return (`${this.nameComponent}-${Object.keys(this.model)[index]}`).toLowerCase();
    }

    private initializeCellCanvas() {
        this.model = {
            cell00: [],
            cell01: [],
            cell02: [],
            cell03: [],
            cell04: [],
            cell05: [],
            cell06: [],
            cell07: [],
            cell08: [],
            cell09: [],
            cell10: [],
            cell11: [],
            cell12: [],
        };
    }
}
