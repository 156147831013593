import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export default class Validation {
    static match(controlName: string, checkControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[checkControlName];
            if (matchingControl.errors && !matchingControl.errors.matching) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ matching: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
