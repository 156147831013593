import { Injectable } from '@angular/core';

declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  images(): Promise<any> {
    const fields = 'name,photos.fields(images)';

    return new Promise((resolve, reject) => {
      FB.api(`/me/albums`, 'get', { fields }, (response: any) => {
        if (!response || response.error) {
          resolve([]);
        } else {
          resolve(response?.data);
        }
      });
    });
  }

  status(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus((status: any) => {
        resolve(status.authResponse !== null);
      });
    });
  }

  login(): Promise<void> {
    return new Promise((resolve, reject) => {
      FB.login(() => resolve(), {
        scope: 'user_photos',
        auth_type: 'reauthenticate',
      });
    });
  }
}
