<section class="container">
  <div class="title">
    <h2>3. Definir Borda</h2>
    <hr />
  </div>
  <hr />
  <div class="form-group">
    <div class="input-group">
      <input class="form-control" [value]="borderSize" type="number" min="0" max="10"
        (change)="changeBorderSize($event)" />
      <input class="form-control" type="color" value="{{borderColor}}" [(ngModel)]="borderColor"
        (change)="changeBorderColor($event)" />
    </div>
  </div>
  <hr />
</section>

<section class="container">
  <div class="content">
    <button class="btn-add" mat-raised-button (click)="openDialogUpload()">2. Adicionar Fotos</button>
    <input hidden type="file" #fileInput (change)="filesInput($event)" multiple>
  </div>
  <div class="title">
    <h2>Galeria de Uploads</h2>
    <hr />
  </div>
</section>

<section class="container" *ngIf="files.length > 0">
  <div class="row" style="background-color: var(--color-gray-20);">
    <div class="form-check" style="margin-right: 50px;">
      <button mat-icon-button (click)="excludeImageSelectedOrAll()" aria-label="Excluir os itens">
        <mat-icon style="font-size: 1rem;">delete_outline</mat-icon>
        <span class="subTitle">Apagar</span>
      </button>
    </div>
    <div class="form-check" style="top: 1vh;">
      <input #checkboxAll class="form-check-input" type="checkbox" id="flexCheckDefault" (change)="selectAll(checkboxAll.checked)">
      <label class="form-check-label subTitle" for="flexCheckDefault">
        Selecionar todos
      </label>
    </div>
  </div>
  <hr />
  
  <div class="row" cdkDropList id="dropPhoto" [cdkDropListData]="files" [cdkDropListConnectedTo]="connectedTo"
    cdkDropListSortingDisabled>
    <div *ngFor="let file of files; let i = index" cdkDrag style="height: 100%;">
        <dws-upload-task [file]="file" [index]='i' (toExclude)="deleteImage($event)"
        (toSelect)="selectImage($event)">
      </dws-upload-task>
    </div>
  </div>
</section>

