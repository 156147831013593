<section class="content" [ngSwitch]="orientation">
  <section class="container" *ngSwitchCase="orientationTemplate.square">
    <div class="box-1">
      <div #square4="routerLinkActive" (click)="clickCells(4)" routerLink="/templates/square/4" routerLinkActive="active" mat-icon-button matTooltip="4">
        <dws-icon-seize [color]="square4.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="4" width="50px" height="50px"></dws-icon-seize>
      </div>
      <div #square7="routerLinkActive" (click)="clickCells(7)" routerLink="/templates/square/7" routerLinkActive="active" mat-icon-button matTooltip="7">
        <dws-icon-seize [color]="square7.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="7" width="50px" height="50px"></dws-icon-seize>
      </div>
      <div #square8="routerLinkActive" (click)="clickCells(8)" routerLink="/templates/square/8" routerLinkActive="active" mat-icon-button matTooltip="8">
        <dws-icon-seize [color]="square8.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="8" width="50px" height="50px"></dws-icon-seize>
      </div>
      <div #square9="routerLinkActive" (click)="clickCells(9)" routerLink="/templates/square/9" routerLinkActive="active" mat-icon-button matTooltip="9">
        <dws-icon-seize [color]="square9.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="9" width="50px" height="50px"></dws-icon-seize>
      </div>
      <div #square10="routerLinkActive" (click)="clickCells(10)" routerLink="/templates/square/10" routerLinkActive="active" mat-icon-button matTooltip="10">
        <dws-icon-seize [color]="square10.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="10" width="50px" height="50px"></dws-icon-seize>
      </div>
      <div #square13="routerLinkActive" (click)="clickCells(13)" routerLink="/templates/square/13" routerLinkActive="active" mat-icon-button matTooltip="13">
        <dws-icon-seize [color]="square13.isActive ? 'var(--color-primary-6)' : ''" icon="cell square" cells="13" width="50px" height="50px"></dws-icon-seize>
      </div>
    </div>
  </section>

  <section class="container" *ngSwitchCase="orientationTemplate.vertical">
    <div class="box-1">
      <div #vertical4="routerLinkActive" (click)="clickCells(4)" routerLink="/templates/vertical/4" routerLinkActive="active" mat-icon-button matTooltip="4">
        <dws-icon-seize [color]="vertical4.isActive ? 'var(--color-primary-6)' : ''" icon="cell vertical" cells="4" width="50px" height="70px"></dws-icon-seize>
      </div>
      <div #vertical7="routerLinkActive" (click)="clickCells(7)" routerLink="/templates/vertical/7" routerLinkActive="active" mat-icon-button matTooltip="7">
        <dws-icon-seize [color]="vertical7.isActive ? 'var(--color-primary-6)' : ''" icon="cell vertical" cells="7" width="50px" height="70px"></dws-icon-seize>
      </div>
      <div #vertical8="routerLinkActive" (click)="clickCells(8)" routerLink="/templates/vertical/8" routerLinkActive="active" mat-icon-button matTooltip="8">
        <dws-icon-seize [color]="vertical8.isActive ? 'var(--color-primary-6)' : ''" icon="cell vertical" cells="8" width="50px" height="70px"></dws-icon-seize>
      </div>
      <div #vertical9="routerLinkActive" (click)="clickCells(9)" routerLink="/templates/vertical/9" routerLinkActive="active" mat-icon-button matTooltip="9">
        <dws-icon-seize [color]="vertical9.isActive ? 'var(--color-primary-6)' : ''" icon="cell vertical" cells="9" width="50px" height="70px"></dws-icon-seize>
      </div>
      <div #vertical13="routerLinkActive" (click)="clickCells(13)" routerLink="/templates/vertical/13" routerLinkActive="active" mat-icon-button matTooltip="13">
        <dws-icon-seize [color]="vertical13.isActive ? 'var(--color-primary-6)' : ''" icon="cell vertical" cells="13" width="50px" height="70px"></dws-icon-seize>
      </div>
    </div>
  </section>

  <section class="container" *ngSwitchCase="orientationTemplate.horizontal">
    <div class="box-2">
      <div #horizontal4="routerLinkActive" (click)="clickCells(4)" routerLink="/templates/horizontal/4" routerLinkActive="active" mat-icon-button matTooltip="4">
        <dws-icon-seize [color]="horizontal4.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="4" width="80px" height="50px"></dws-icon-seize>
      </div>
      <div #horizontal6="routerLinkActive" (click)="clickCells(6)" routerLink="/templates/horizontal/6" routerLinkActive="active" mat-icon-button matTooltip="6">
        <dws-icon-seize [color]="horizontal6.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="6" width="80px" height="50px"></dws-icon-seize>
      </div>
      <div #horizontal8="routerLinkActive" (click)="clickCells(8)" routerLink="/templates/horizontal/8" routerLinkActive="active" mat-icon-button matTooltip="8">
        <dws-icon-seize [color]="horizontal8.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="8" width="80px" height="50px"></dws-icon-seize>
      </div>
      <div #horizontal8a="routerLinkActive" (click)="clickCells(8.1)" routerLink="/templates/horizontal/8a" routerLinkActive="active" mat-icon-button matTooltip="8A">
        <dws-icon-seize [color]="horizontal8a.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="8A" width="80px" height="50px"></dws-icon-seize>
      </div>
      <div #horizontal13="routerLinkActive" (click)="clickCells(13)" routerLink="/templates/horizontal/13" routerLinkActive="active" mat-icon-button matTooltip="13">
        <dws-icon-seize [color]="horizontal13.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="13" width="80px" height="50px"></dws-icon-seize>
      </div>
      <div #horizontal13a="routerLinkActive" (click)="clickCells(13.1)" routerLink="/templates/horizontal/13a" routerLinkActive="active" mat-icon-button matTooltip="13A">
        <dws-icon-seize [color]="horizontal13a.isActive ? 'var(--color-primary-6)' : ''" icon="cell horizontal" cells="13A" width="80px" height="50px"></dws-icon-seize>
      </div>
    </div>
  </section>
</section>
