<section class="container">
  <div class="title">
    <h2>PAPEL DE PAREDE</h2>
  </div>
  <h2></h2>
  <hr />

  <div class="form-group">
    <div class="input-group">
      <button class="btn-save" [ngClass]="disabledQtdPhotos ? 'btn-save-disabled' : 'btn-save-enable'" mat-raised-button
        (click)="generateWallpaper()">
        {{
        qtdImagens < qtdImagensRequeridas ? "Necessário " + qtdImagensRequeridas + " fotos para gerar a arte" :
          qtdImagens> qtdIdeal ? "A quantidade máxima é de " + qtdIdeal + " fotos" : "4. Aplicar fotos para geração da
          arte"
          }}
      </button>
    </div>
  </div>
  <hr />
</section>

<section class="container">
  <div class="content">
    <button class="btn-add" mat-raised-button (click)="openDialogUpload()">
      {{ isMobile ? "Adicionar Fotos" : "3. Adicionar Fotos" }}
    </button>
  </div>
  <div class="title">
    <h2>Galeria de Uploads</h2>
    <hr />
  </div>
</section>

<section class="container" *ngIf="files.length > 0">
  <div class="row" style="background-color: var(--color-gray-20)">
    <div class="form-check">
      <label class="form-check-label subTitle" for="flexCheckDefault" style="height: 40px; padding: 12px 0;">
        <span class="subTitle">{{ qtdImagens }} Fotos importadas</span>
      </label>
    </div>
    <div class="form-check" style="margin-right: 50px">
      <button mat-icon-button (click)="excludeImageSelectedOrAll()" aria-label="Excluir os itens">
        <mat-icon style="font-size: 1rem;">delete_outline</mat-icon>
        <span class="subTitle">Apagar {{ filesSelected ? " selecionados" : " todos" }}</span>
      </button>
    </div>
  </div>
  <hr />

  <div class="row">
    <div *ngFor="let file of files; let i = index" style="height: 100%">
      <dws-upload-task [file]="file" [index]="i" (toExclude)="deleteImage($event)" (toEdit)="editImage($event)"
        (toSelect)="selectImage($event)">
      </dws-upload-task>
    </div>
  </div>
</section>

<ngx-photo-editor [imageUrl]="imageUrl" (imageCropped)="updateImage($event)" [aspectRatio]="4 / 3" [viewMode]="3"
  [resizeToWidth]="500">
</ngx-photo-editor>