import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{}'));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      })
    };
    return this.http.get<any>(`${environment.urlFrontend}/wp-json/wp/v2/users/me`, httpOptions)
      .pipe(map(user => {
        console.log(user);
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next({} as User);
  }

  register(user: User) {
    return this.http.post(`${environment.urlFrontend}/users/register`, user);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.urlFrontend}/users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${environment.urlFrontend}/users/${id}`);
  }

  update(id: string, params: any[]) {
    return this.http.put(`${environment.urlFrontend}/users/${id}`, params)
      .pipe(map(x => {
        if (id === this.userValue.id) {
          const user = { ...this.userValue, ...params };
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
        }
        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.urlFrontend}/users/${id}`)
      .pipe(map(x => {
        if (id === this.userValue.id) {
          this.logout();
        }
        return x;
      }));
  }
}
