import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlider, MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'dws-editor-photo',
  templateUrl: './editor-photo.component.html',
  styleUrls: ['./editor-photo.component.scss']
})
export class EditorPhotoComponent implements OnInit {

  urlImage: string;

  private gsVal: number;
  private blurVal: number;
  private huerotateVal: number;
  private sepiaVal: number;
  private brightnessVal: number;
  private contrastVal: number;
  private invertVal: number;
  private opacityVal: number;
  private saturateVal: number;

  private filterCss: string;

  get filter() {
    return this.filterCss;
  }

  constructor(
    public dialogRef: MatDialogRef<EditorPhotoComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.urlImage = data.url;
    this.filterCss = data.grayscale;
  }

  ngOnInit(): void {
    this.initVal();
  }

  makeGrayscale(event: MatSliderChange) {
    this.gsVal = event.value || 0;
    this.editImage();
  }

  makeBlur(event: MatSliderChange) {
    this.blurVal = event.value || 0;
    this.editImage();
  }

  makeHuerotate(event: MatSliderChange) {
    this.huerotateVal = event.value || 0;
    this.editImage();
  }

  makeSepia(event: MatSliderChange) {
    this.sepiaVal = event.value || 0;
    this.editImage();
  }

  makeBrightness(event: MatSliderChange) {
    this.brightnessVal = event.value || 0;
    this.editImage();
  }

  makeContrast(event: MatSliderChange) {
    this.contrastVal = event.value || 0;
    this.editImage();
  }

  makeInvert(event: MatSliderChange) {
    this.invertVal = event.value || 0;
    this.editImage();
  }

  makeOpacity(event: MatSliderChange) {
    this.opacityVal = event.value || 0;
    this.editImage();
  }

  makeSaturate(event: MatSliderChange) {
    this.saturateVal = event.value || 0;
    this.editImage();
  }

  restore() {
    this.initVal();
    this.editImage();
    this.save();
  }

  save(): void {
    this.dialogRef.close(this.filterCss);
  }

  private editImage() {
    this.filterCss = `
      grayscale(${this.gsVal}%)
      blur(${this.blurVal}px)
      hue-rotate(${this.huerotateVal}deg)
      sepia(${this.sepiaVal}%)
      brightness(${this.brightnessVal}%)
      contrast(${this.contrastVal}%)
      invert(${this.invertVal}%)
      opacity(${this.opacityVal}%)
      saturate(${this.saturateVal}%)
      `;
  }

  private initVal() {
    this.gsVal = 0;
    this.blurVal = 0;
    this.huerotateVal = 0;
    this.sepiaVal = 0;
    this.brightnessVal = 100;
    this.contrastVal = 100;
    this.invertVal = 0;
    this.opacityVal = 100;
    this.saturateVal = 100;
  }
}
