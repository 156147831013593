export interface Auth {
    name: string;
    last_name: string;
    email: string;
    doc_number: string;
    phone: string;
    address_street: string;
    address_number: string;
    address_complement: string;
    address_district: string;
    address_city: string;
    address_state: string;
    password: string;
    confirm_password: string;
}
