import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-s4',
  templateUrl: './s4.component.html',
  styleUrls: ['./s4.component.scss']
})
export class S4Component extends Canvas {

  constructor() {
    super(S4Component, 4);
  }

}
