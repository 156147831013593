import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Client, Result } from '@app/models';
import { AlertService } from '@app/services';
import { BehaviorSubject, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export class ServiceBase {

    protected loggedSubject = new BehaviorSubject<boolean>(false);

    constructor(private alertService: AlertService) { }

    get isLogged(): boolean {
        return localStorage.getItem('data-token') ? true : false;
    }

    get logged$() {
        return this.loggedSubject.asObservable();
    }

    get token(): string | undefined {
        if (this.isLogged) {
            const result = JSON.parse(localStorage.getItem('data-token') ?? '') as Result;
            return result.token ?? undefined;
        }
        return undefined;
    }

    get currentUserValue(): string {
        if (this.isLogged) {
            const result = JSON.parse(localStorage.getItem('data-token') ?? '') as Result;
            return result.name;
        }
        return '';
    }

    protected createOptions() {
        const folderToken = localStorage.getItem('folder-token') || uuidv4();
        localStorage.setItem('folder-token', folderToken);
        return {
            headers: new HttpHeaders({
                'folder-token': folderToken,
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.token}`
            })
        };
    }

    protected handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ocorreu um erro:', error.error.message);
            this.alertService.error(error.error.message);
        } else {
            console.error(
                `Código de erro ${error.status}, ` +
                `Erro: ${error.error}`);
            this.alertService.error(error.error);
        }
        return throwError('Tente novamente mais tarde');
    }
}
