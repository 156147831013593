import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Client } from './../models/client.model';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ServiceBase {

  private uri = `http://161.97.163.176:5000`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  login(email: string, password: string) {
    return this.http.post(`${this.uri}/auth/login`, { email, password })
      .pipe(tap(data => {
        localStorage.setItem('data-token', JSON.stringify(data));
        this.loggedSubject.next(true);
      }));
  }

  logout() {
    localStorage.clear();
    this.loggedSubject.next(false);
  }

  register(clientForm: any) {
    const client: Client = {
      name: clientForm.name,
      email: clientForm.email,
      password: clientForm.password
    };

    return this.http.post(`${this.uri}/auth/signup`, client).pipe(
      map((response) => response),
      catchError(this.handleError));
  }

  getAccount(): Observable<Client> {
    console.log(this.token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      })
    };
    return this.http.get<Client>(`${this.uri}/account/`, httpOptions);
  }
}
