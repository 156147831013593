import { Injectable } from '@angular/core';
import { ApplicationName, IconMenu } from '@app/helpers/enumerators';
import { ItemMenu } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }

  getItensMenu(implemented: ApplicationName): ItemMenu[] {
    return this.itensMenu().filter(item => item.implemented.includes(implemented));
  }

  private itensMenu(): ItemMenu[] {
    return [
      {
        text: 'Importar',
        icon: IconMenu.upload,
        implemented: [ApplicationName.signature]
      },
      {
        text: 'Galeria',
        icon: IconMenu.gallery,
        implemented: [ApplicationName.signature]
      },
      {
        text: 'Entrega',
        icon: IconMenu.settings,
        implemented: [ApplicationName.signature]
      },
      {
        text: '1. Confirmar Template',
        icon: IconMenu.template,
        implemented: [ApplicationName.wallpaper]
      },
      {
        text: '1. Importar',
        icon: IconMenu.upload,
        implemented: [ApplicationName.collage]
      },
      {
        text: '2. Importar Fotos',
        icon: IconMenu.upload,
        implemented: [ApplicationName.wallpaper]
      },
      {
        text: 'Texto (opcional)',
        icon: IconMenu.text,
        implemented: [ApplicationName.collage]
      },
      {
        text: 'Galeria (opcional)',
        icon: IconMenu.gallery,
        implemented: [ApplicationName.collage]
      },
    ];
  }
}
