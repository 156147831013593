<mat-toolbar class="header">
    <mat-toolbar-row>
        <div class="flex">
            <div class="logo">
                <div class="logo-icon">
                    <a href="https://lifewall.art/">
                        <img src="../../../assets/img/logo_seize.png" height="110vh">
                    </a>
                </div>
                <div class="logo-text">
                    <h1>{{title}}</h1>
                </div>
            </div>
        </div>
        <span style="flex: auto;"></span>
        <div class="flex" *ngIf="loggedIn">
            <h1 style="margin-top: 10px;">{{clientName}}</h1>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="btn-icon person">
                <mat-icon>person</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="update()">Editar</button>
                <button mat-menu-item (click)="logout()">Sair</button>
            </mat-menu>
        </div>
        <a mat-icon-button aria-label="lifewall.art" href="https://lifewall.art/" class="btn-icon home">
            <mat-icon>home</mat-icon>
        </a>
    </mat-toolbar-row>
</mat-toolbar>