import { Component, OnInit } from '@angular/core';
import { Canvas } from '@app/utils/canvas';

@Component({
  selector: 'dws-h1',
  templateUrl: './h1.component.html',
  styleUrls: ['./h1.component.scss']
})
export class H1Component extends Canvas {

  constructor() {
    super(H1Component, 1);
   }


}
