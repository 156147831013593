import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-h13',
  templateUrl: './h13.component.html',
  styleUrls: ['./h13.component.scss']
})
export class H13Component extends Canvas {

  constructor() {
    super(H13Component, 13);
  }
}
