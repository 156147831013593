<div class="login">
  <div class="card shadow-lg p-3 mb-5 bg-white rounded">
    <div class="text-center">
      <h3>RECUPERAR SENHA</h3>
    </div>
    <div class="card-body">
      <form [formGroup]="loginForm" (ngSubmit)="recuperar()">
        <div class="form-group col-md-12">
          <label for="email">E-mail</label>
          <input type="text" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && loginControls.email.errors }" />
          <div *ngIf="submitted && loginControls.email.errors" class="invalid-feedback">
            <div *ngIf="loginControls.email.errors?.required">Email obrigatório</div>
          </div>
        </div>
        <div class="form-group col-md-12">
          <button [routerLink]="['/signature/login']" class="btn btn-danger float-left">
            Cancelar
          </button>
          <button [disabled]="loading" class="btn btn-primary float-right">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Recuperar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>