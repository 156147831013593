
export class Globals {
    private static text = 'Carregando...';

    private static url = '';
    private static previewImage = false;

    static get isMobile() {
        if (window.screen.width < 800) {
            return true;
        }
        return false;
    }

    static get preview() {
        return this.previewImage;
    }

    static set preview(previewImage: boolean) {
        this.previewImage = previewImage;
    }

    static set loadingText(text: string) {
        this.text = text;
    }

    static get loadingText() {
        return this.text;
    }

    static getNumberPhotosBySku(sku: string) {
        switch (sku) {
            case 'qpics_q1_mod01':
            case 'qpics_h1_mod01':
            case 'qpics_v1_mod01':
                return 1;

            case 'qpics_q4_mod01':
            case 'qpics_h4_mod01':
            case 'qpics_v4_mod01':
                return 4;

            case 'qpics_h6_mod01':
                return 6;

            case 'qpics_q7_mod01':
            case 'qpics_v7_mod01':
                return 7;

            case 'qpics_q8_mod01':
            case 'qpics_h8_mod01':
            case 'qpics_h8_mod02':
            case 'qpics_v8_mod01':
                return 8;

            case 'qpics_q9_mod01':
                return 9;

            case 'qpics_q10_mod01':
            case 'qpics_v9_mod01':
                return 10;

            case 'qpics_q13_mod01':
            case 'qpics_h13_mod01':
            case 'qpics_h13_mod02':
            case 'qpics_v13_mod01':
                return 13;

            default:
                return 0;
        }
    }

    static set urlWallpaper(url: string) {
        this.url = url;
    }

    static get urlWallpaper() {
        if (this.url) {
            return this.url;
        }
        return '';
    }
}
