​<picture>
  <div class="img-wrap">
    <button mat-icon-button class="close" (click)="exclude(index)">
      <mat-icon>close</mat-icon>
    </button>
    <input #checkboxImg type="checkbox" id="cd{{index}}" (change)="select(file, checkboxImg.checked)"
      [checked]="file.selected" />
    
    <label for="cd{{index}}">
      <img [src]="file.url" class="rounded mx-auto d-block">
    </label>

    <span *ngIf="file.baixaresolucao" class="notify-badge">Imagem de menor resolução</span>
  </div>
</picture>
