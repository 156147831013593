import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-s10',
  templateUrl: './s10.component.html',
  styleUrls: ['./s10.component.scss']
})
export class S10Component extends Canvas {

  constructor() {
    super(S10Component, 10);
  }

}
