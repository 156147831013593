import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-v8',
  templateUrl: './v8.component.html',
  styleUrls: ['./v8.component.scss']
})
export class V8Component extends Canvas {

  constructor() {
    super(V8Component, 8);
  }
}
