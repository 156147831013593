<div class="position-relative d-inline-block">
  <div (click)="upadte()">
    <img #image class="image-container" />
  </div>
  <mat-checkbox
    #checkbox
    [checked]="selected"
    (change)="upadte()"
    color="warn"
    class="position-absolute checkbox-pos"
  ></mat-checkbox>
</div>
