import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileHandle } from '@app/models';
import { UploadService } from './../../api/backend/upload.service';

@Component({
  selector: 'dws-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: FileHandle;
  @Input() index: number;

  @Output() toEdit = new EventEmitter();
  @Output() toSelect = new EventEmitter();
  @Output() toExclude = new EventEmitter();

  constructor(private uploadService: UploadService) {
  }

  ngOnInit(): void {
    this.uploadService.getImageDimension(this.file)
      .subscribe(response => {
        if (response.width < 600 || response.height < 600) {
          this.file.baixaresolucao = true;
        } else {
          this.file.baixaresolucao = false;
        }
      });
  }

  isActive(snapshot: any) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  exclude(index: number) {
    this.toExclude.emit(index);
  }

  edit(file: FileHandle, index: number) {
    this.toEdit.emit({ file, index });
  }

  select(file: FileHandle, checked: boolean) {
    this.toSelect.emit({ file, checked });
  }
}
