import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {

  private refresh = new BehaviorSubject<boolean>(false);

  constructor() { }

  setRefresh(reload: boolean) {
    this.refresh.next(reload);
  }

  getRefresh(): Observable<boolean> {
    return this.refresh.asObservable();
  }

}
