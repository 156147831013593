import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService extends ServiceBase {

  private uri = `${environment.urlFrontend}/wp-json`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  addItemCart(item: Item): Observable<any> {
    return this.http.post(`${this.uri}/cocart/v1/add-item/?cart_key=${localStorage.getItem('folder-token')}`, item, { observe: 'response' })
      .pipe(
        tap(response => response),
        catchError(this.handleError)
      );
  }

  getCart(): Observable<any> {
    return this.http.get(`${this.uri}/cocart/v1/get-cart`, { observe: 'response' }).pipe(
      tap(cart => cart)
    );
  }

  clearCart(): Observable<any> {
    return this.http.post(`${this.uri}/cocart/v1/clear/?cart_key=${localStorage.getItem('folder-token')}`, { observe: 'response' });
  }

}


export interface Item {
  product_id: string;
  quantity: number;
  variation: Variation;
}

export interface Variation {
  attribute_tamanho: string;
  attribute_material?: string;
  attribute_comprimento?: string;
  attribute_largura?: string;
}

export interface ItemCart {
  key: string;
  product_id: number;
  variation_id: number;
  variation: Variation;
  quantity: number;
  data_hash: string;
  line_subtotal: number;
  line_total: number;
  product_name: string;
  product_title: string;
  product_price: string;
}
