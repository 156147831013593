<mat-toolbar class="footer" *ngIf="!isSignature">

  <button type="button" class="btn btn-seize secundario" tooltip="Veja como montar seu poster."
    (click)="openVideo()">
    Vídeo<br />explicativo
  </button>

  <button type="button" class="btn btn-seize secundario" tooltip="Dicas para montar seu poster."
    (click)="openDicas()">
    Dicas
  </button>

  <div class="div-salve">
    <h5>Transferência de Fotos entre <br *ngIf="isMobile" />Celular e Computador</h5>
    <button type="button" class="btn btn-photos save" tooltip="Salvar o fotos." (click)="openDialog('tel')">
      Salvar Fotos
    </button>
    <button type="button" class="btn btn-photos recover" tooltip="Recuperar fotos salvas" (click)="openDialog('code')">
      Recuperar Fotos
    </button>
  </div>

  <button *ngIf="isWallpaper && !isSignature" type="button" class="btn btn-seize secundario"
    tooltip="Criar um novo projeto." (click)="novoProjeto()">
    Novo Projeto
  </button>

  <button type="button" [disabled]="disabledCheckout" class="btn btn-seize primario"
    (click)="gerarPng()">
    {{ textButton }}
  </button>

</mat-toolbar>

<mat-toolbar class="footer" *ngIf="isSignature && isLogged">

  <button type="button" [disabled]="disabledCheckout" class="btn btn-seize primario"
    (click)="gerarPng()">
    {{ textButton }}
  </button>

</mat-toolbar>