<section class="container">
    <div class="card-body" style="max-height: 30vw; overflow: auto;">
        <div class="row g-3" *ngIf="isLogged">
            <div class="form-group col-6">
                <label for="meu_plano">Plano</label>
                <input type="text" class="form-control"  autocomplete="off" />
            </div>

            <div class="form-group col-6">
                <label for="total_fotos">Total de fotos</label>
                <input type="text" class="form-control" autocomplete="off" />
            </div>
        </div>

        <div class="row g-3" *ngIf="isLogged">
            <div class="form-group col-6">
                <label for="meu_plano">Tamanho</label>
                <input type="text" class="form-control"  autocomplete="off" />
            </div>

            <div class="form-group col-6">
                <label for="total_fotos">Formato</label>
                <input type="text" class="form-control"  autocomplete="off" />
            </div>
        </div>
        <hr />
        <form [formGroup]="registerForm" (ngSubmit)="register()" class="row g-3">

            <div class="form-group col-6">
                <label for="name">Nome</label>
                <input type="text" formControlName="name" class="form-control" [(ngModel)]="client.name"
                    autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors?.required">Nome é obrigatório.</div>
                </div>
            </div>

            <div class="form-group col-6">
                <label for="email">E-mail</label>
                <input type="email" formControlName="email" class="form-control" [(ngModel)]="client.email"
                    autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.required">E-mail é obrigatório</div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="password">Senha</label>
                <input type="password" formControlName="password" class="form-control" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors?.required">Senha é obrigatória</div>
                    <div *ngIf="f.password.errors?.minlength">Senha deve ter no mínimo 6 caracteres</div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="confirm_password">Confirma Senha</label>
                <input type="password" formControlName="confirm_password" class="form-control" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" />
                <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                    <div *ngIf="f.confirm_password.errors?.required">Senha é obrigatória</div>
                    <div *ngIf="f.confirm_password.errors?.minlength">Senha deve ter no mínimo 6 caracteres</div>
                    <div *ngIf="f.confirm_password.errors?.matching">Senha e confirmação devem ser iguais</div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group float-right">
                    <button class="btn btn-danger mr-1" (click)="goBackSignature()">
                        Cancelar
                    </button>
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Salvar
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>
