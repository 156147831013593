import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dws-icon-seize [icon]',
  templateUrl: './icon-seize.component.html',
  styleUrls: ['./icon-seize.component.scss']
})
export class IconSeizeComponent implements OnInit {

  @Input() cells: string;
  @Input() color: string;
  @Input() height: string;
  @Input() icon: string;
  @Input() width: string;

  get iconInput(): string {
    const icon = this.icon.toLowerCase();

    if (this.cells) {
      const cell = this.cells.toLowerCase();
      if (icon.indexOf('horizontal') !== -1) {
        this.width = this.width || '30px';
        this.height = this.height || '22px';
      } else if (icon.indexOf('vertical') !== -1) {
        this.width = this.width || '22px';
        this.height = this.height || '30px';
      }
      return `svg ${icon} _${cell}`;
    }

    this.width = this.width || '20px';
    this.height = this.height || '20px';
    return `svg ${icon}`;
  }

  get colorInput(): string {
    return this.color || '';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
