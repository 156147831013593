import { ProjectResult, ProjectService } from './../../api/backend/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@app/services/api.service';

@Component({
  selector: 'dws-validation-code-dialog',
  templateUrl: './validation-code-dialog.component.html',
  styleUrls: ['./validation-code-dialog.component.scss']
})
export class ValidationCodeDialogComponent implements OnInit {

  codeForm: FormGroup;
  modal = 'tel';

  titleCode = '';
  code: string;
  isCode: boolean;
  reload = false;

  get isModalSave() {
    return this.modal === 'tel';
  }

  get isValidButton() {
    return this.isModalSave ? this.codeForm.controls.phone.invalid :
      (this.codeForm.controls.phone.invalid || this.codeForm.controls.code.invalid);
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ValidationCodeDialogComponent>,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.modal = data;
    this.isCode = false;
    this.createForm();
  }

  ngOnInit(): void {
  }

  onNoClick(reload: boolean): void {
    this.dialogRef.close(reload);
  }

  createForm() {
    this.codeForm = this.fb.group({
      phone: ['', [Validators.pattern(/^\(\d{2}\)\s\d{5}-\d{4}$/), Validators.required]],
      code: ['', [Validators.minLength(6), Validators.required]],
    });
  }

  submitModal() {
    if (this.isModalSave) {
      this.save();
    } else {
      this.recovery();
    }
  }

  private save() {
    const cel = this.codeForm.controls.phone.value.replace(/[^\d]/gm, '');
    this.projectService.save({ telefone: cel }).subscribe(res => {
      if (res) {
        this.titleCode = 'Mensagem do código de recuperação enviado para seu celular. Posteriormente utilize este código para recuperar suas fotos!';
        this.code = res.codigosms || '';
        this.isCode = true;
      }
    });
  }

  private recovery() {
    const phone = this.codeForm.controls.phone.value;
    const recover = {
      telefone: phone.replace(/[^\d]/gm, ''),
      codigosms: this.codeForm.controls.code.value
    };

    this.projectService.toRecover(recover).subscribe(res => {
      this.isCode = true;
      if (res.folderToken) {
        this.reload = true;
        localStorage.setItem('folder-token', res.folderToken);
        localStorage.setItem('wasGenerated', 'true');
        this.titleCode = 'Suas fotos foram importadas com sucesso.';
        this.code = 'Acesse "Importar" no Menu lateral e conclua sua arte.';
      } else {
        this.titleCode = `Código ${recover.codigosms} inválido para o telefone ${phone}.`;
      }
    });
  }

}
