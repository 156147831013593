<div class="login">
  <div class="card shadow-lg p-3 mb-5 bg-white rounded">
    <div class="text-center">
      <h3>LOGIN</h3>
      <p class="text-muted font-weight-bold font-size-h4">
        Ou crie
        <a [routerLink]="['/signature/register']" class="text-primary font-weight-bolder" tabindex="1">
          uma conta
        </a>
      </p>
    </div>
    <div class="card-body">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="form-group col-md-12">
          <label for="email">E-mail</label>
          <input type="text" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && loginControls.email.errors }" />
          <div *ngIf="submitted && loginControls.email.errors" class="invalid-feedback">
            <div *ngIf="loginControls.email.errors?.required">Email obrigatório</div>
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="password">Senha</label>
          <a [routerLink]="['/signature/recover']" class="text-primary font-weight-bolder float-right">
            Esqueci a senha?
          </a>
          <input type="password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && loginControls.password.errors }" />
          <div *ngIf="submitted && loginControls.password.errors" class="invalid-feedback">
            <div *ngIf="loginControls.password.errors?.required">Senha obrigatória</div>
          </div>
        </div>
        <div class="form-group col-md-12">
          <button [disabled]="loading" class="btn btn-primary float-right">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Login
          </button>
        </div>

      </form>
    </div>
  </div>
</div>