<mat-drawer-container>
  <mat-drawer class="menu-fix" mode="side" [opened]="true" *ngIf="!isMobile || menuWallpaper">
    <div *ngIf="!isSignature">
      <div class="menu-item" *ngFor="let item of itemsMenu" (click)="toggle(item)"
        [ngClass]="{active: iconMenu.upload === item.icon || iconMenu.template === item.icon}">
        <dws-icon-seize [icon]="getIconName(item.icon)" width="20px" height="20px" color="var(--lifewall-black)">
        </dws-icon-seize>
        <div class="item-text">
          {{item.text}}
        </div>
      </div>
    </div>
    <div *ngIf="isSignature && loggedIn">
      <div class="menu-item" *ngFor="let item of itemsMenu" (click)="clickMenuSignature(item)">
        <dws-icon-seize [icon]="getIconName(item.icon)" width="20px" height="20px" color="var(--lifewall-black)">
        </dws-icon-seize>
        <div class="item-text">
          {{item.text}}
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-sidenav-container>
    <mat-sidenav class="sidenav" #sidenav [opened]="open" mode="side"
      *ngIf="!isSignature && (!isMobile || menuWallpaper)">
      <ngx-simplebar [options]="options">
        <div [ngSwitch]="tipo">

          <div *ngSwitchCase="iconMenu.template">
            <dws-template-wallpaper *ngIf="menuWallpaper"></dws-template-wallpaper>
          </div>

          <div *ngSwitchCase="iconMenu.upload">
            <dws-upload-image *ngIf="!menuWallpaper"></dws-upload-image>
            <dws-import-wallpaper *ngIf="menuWallpaper"></dws-import-wallpaper>
          </div>

          <div *ngSwitchCase="iconMenu.text">
            <dws-generate-text *ngIf="!menuWallpaper"></dws-generate-text>
          </div>

          <div *ngSwitchCase="iconMenu.gallery">
            <dws-gallery *ngIf="!menuWallpaper"></dws-gallery>
          </div>

        </div>
      </ngx-simplebar>
    </mat-sidenav>

    <mat-sidenav-content>
      <ngx-simplebar [options]="options">
        <ng-content></ng-content>
      </ngx-simplebar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-drawer-container>