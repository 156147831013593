import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '@app/api';
import { TypeGallery } from '@app/components/gallery/gallery.component';
import { FileHandle } from '@app/models';
import { RefreshService } from '@app/services/refresh.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dws-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  selectedGallery = 'photo';
  titleGallery: TypeGallery;
  files: FileHandle[] = [];
  filesPresentation: FileHandle[] = [];
  page = 0;
  size = 9;

  get maxPhotos() {
    const sku = localStorage.getItem('sku');
    switch (sku) {
      case 'q12_mensal_mod1':
        return 30;

      default:
        return 100;
    }
  }

  get porcentagem() {
    const qtd = this.files.length;
    return Math.floor((qtd / this.maxPhotos) * 100);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private imageService: UploadService,
    private spinner: NgxSpinnerService,
    private refreshService: RefreshService) {
    refreshService.getRefresh().subscribe(reload => {
      if (reload) {
        this.getImages();
      }
    });
  }

  ngOnInit(): void {
    this.getImages();
  }

  deleteImage(index: number) {
    this.spinner.show();
    const id = this.files[index]._id || '';
    this.imageService.delete(id).subscribe(() => {
      this.files.splice(index, 1);
    })
      .add(() => this.spinner.hide());
  }

  getImages() {
    this.spinner.show();
    this.imageService
      .getPhotos()
      .subscribe((res: any) => {
        this.files = [];
        for (const img of res.results) {
          if (!img.printed) {
            const imageUrl = `${img.imageUrl}`;
            const imgFile = new File([imageUrl], img.imageName, {
              type: 'image/jpeg',
            });
            const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
            const file: FileHandle = {
              _id: img.code ? img.code.toString() : img._id,
              file: imgFile,
              url: urlSafe,
              selected: false,
              strUrl: imageUrl,
            };
            this.files.push(file);
            this.refreshService.setRefresh(false);
          }
        }
      })
      .add(() => this.spinner.hide());
  }


  exclude(index: number) {
    const id = this.files[index]._id || '';
    this.imageService.delete(id).subscribe(() => {
      this.files.splice(index, 1);
    });
  }
}
