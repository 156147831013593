import { Component } from '@angular/core';
import {
  BaseWrapper,
  BaseWrapperEnum,
} from '../base-wrapper/base-wrapper.component';

@Component({
  selector: 'dws-device-wrapper',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceWrapperComponent extends BaseWrapper {
  name = BaseWrapperEnum.DEVICE;
  images: any;

  upload(event: any): void {
    this.images = (event!.target as HTMLInputElement)!.files as FileList;
  }

  dragDrop(files: any) {
    this.images = files;
  }

  get data(): { name: BaseWrapperEnum; images: any } {
    return {
      name: this.name,
      images: this.images,
    };
  }

  get textoImportarFotos() {
    let dispositivo = 'computador';
    if (window.screen.width < 800) {
      dispositivo = 'celular';
    }
    return `Abrir pastas no ${dispositivo}`;
  }
}
