import { Attributes } from './../../../models/attributes.model';
import { Component, OnInit } from '@angular/core';
import { ProductService } from './../../../services/product.service';

@Component({
  selector: 'dws-template-wallpaper',
  templateUrl: './template-wallpaper.component.html',
  styleUrls: ['./template-wallpaper.component.scss']
})
export class TemplateWallpaperComponent implements OnInit {

  backgroundColor = '#FFFFFF';

  sku: string;
  orientation: string;
  material?: string;
  comprimento: string;
  largura: string;
  attributes: Attributes;

  constructor(private productService: ProductService) { }

  get qtdImagensRequeridas() {
    return this.attributes?.minimo;
  }

  get qtdIdeal() {
    return this.attributes?.maximo;
  }

  ngOnInit(): void {
    this.sku = localStorage.getItem('sku') || '';
    this.orientation = localStorage.getItem('orientation') || '';

    this.comprimento = localStorage.getItem('comprimento') || '';
    this.largura = localStorage.getItem('largura') || '';

    this.productService.obterAtributo(this.sku, this.orientation, Number(this.largura))
      .subscribe(atributo => this.attributes = atributo);
  }

  changeColumns(event: Event) {
    localStorage.setItem('colunas', (event.target as HTMLSelectElement).value);
  }

  changeBackgroundColor(event: Event) {
    const color = (event.target as HTMLSelectElement).value;
    document.documentElement.style.setProperty('--wallpaper-background-color', color);
    localStorage.setItem('color', color);
  }

}
