import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Dimension, Template, User } from '@app/models';
import { CheckoutService } from '@app/api/frontend/checkout.service';
import { Globals } from '@app/utils/globals';

@Component({
  selector: 'dws-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
