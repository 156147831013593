import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-s9',
  templateUrl: './s9.component.html',
  styleUrls: ['./s9.component.scss']
})
export class S9Component extends Canvas {

  constructor() {
    super(S9Component, 9);
  }

}
