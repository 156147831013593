import { Component, OnInit } from '@angular/core';
import { TextFormat } from '@app/helpers/enumerators/text-format.enum';
import { FontFamily } from 'src/app/helpers/enumerators/font-family.enum';
import { FileHandle } from 'src/app/models/file-handle.model';
import { StyleHandle } from 'src/app/models/style-handle.model';
import { TextHandle } from 'src/app/models/text-handle.model';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-generate-text',
  templateUrl: './generate-text.component.html',
  styleUrls: ['./generate-text.component.scss']
})
export class GenerateTextComponent implements OnInit {

  private readonly textStorage = 'TEXT_DYNAMIC';

  files: FileHandle[] = [];
  fontFamilyEnum = FontFamily;

  selectedText = '';
  selectedSize = 15;
  selectedFont = '';
  selectedColorFont = '#000000';
  selectedColorBackground = '';
  hasBold = false;
  hasItalic = false;
  hasUnderline = false;
  format = TextFormat;

  textStyle: StyleHandle = {
    fontFamily: this.selectedFont,
    fontSize: `${this.selectedSize}px`,
    backgroundColor: this.selectedColorBackground,
    color: this.selectedColorFont,
    fontStyle: '',
    fontWeight: '',
    textDecoration: '',
    textAlign: TextFormat.text_center,
  };

  get connectedTo(): string[] {
    return [
      ...Canvas.listIdConnection,
    ];
  }

  get textHandle(): TextHandle {
    return this.files.length > 0 && this.files[0].textHandle ? this.files[0].textHandle : {} as TextHandle;
  }

  constructor() {
    this.files[0] = {
      strUrl: '',
      url: '',
      selected: false,
      textHandle: {
        text: '',
        style: this.textStyle,
      }
    };
  }

  ngOnInit(): void {
    if (localStorage.getItem(this.textStorage)) {
      const filesStr = localStorage.getItem(this.textStorage);
      this.files[0].textHandle = filesStr ? JSON.parse(filesStr) as TextHandle : {} as TextHandle;

      this.selectedText = this.files[0].textHandle.text;
      this.selectedSize = (Number)((this.files[0].textHandle.style.fontSize).substr(0, 2));
      this.selectedFont = this.files[0].textHandle.style.fontFamily;
      this.selectedColorFont = this.files[0].textHandle.style.color;
      this.selectedColorBackground = this.files[0].textHandle.style.backgroundColor;

      this.textStyle = {
        fontFamily: this.selectedFont,
        fontSize: `${this.selectedSize}px`,
        backgroundColor: this.selectedColorBackground,
        color: this.selectedColorFont,
        fontStyle: this.files[0].textHandle.style.fontStyle,
        fontWeight: this.files[0].textHandle.style.fontWeight,
        textDecoration: this.files[0].textHandle.style.textDecoration,
        textAlign: this.files[0].textHandle.style.textAlign,
      };
    }
  }

  changeBackgroundColor(event: Event) {
    this.textStyle.backgroundColor = (event.target as HTMLSelectElement).value;
    this.updateTextStyle(this.textStyle);
  }

  changeFontColor(event: Event) {
    this.textStyle.color = (event.target as HTMLSelectElement).value;
    this.updateTextStyle(this.textStyle);
  }

  changeFontFamily(event: Event) {
    this.textStyle.fontFamily = (event.target as HTMLSelectElement).value;
    this.updateTextStyle(this.textStyle);
  }

  changeFontSize(event: Event) {
    this.textStyle.fontSize = `${(event.target as HTMLSelectElement).value}px`;
    this.updateTextStyle(this.textStyle);
  }

  changeText(event: Event) {
    if (this.files[0] && this.files[0].textHandle) {
      this.files[0].textHandle.text = (event.target as HTMLSelectElement).value || '';
      localStorage.setItem(this.textStorage, JSON.stringify(this.files[0].textHandle));
    }
  }

  changeTextFormat(format: TextFormat) {
    switch (format) {
      case TextFormat.bold:
        this.textStyle.fontWeight = this.textStyle.fontWeight === format ? '' : format;
        break;

      case TextFormat.italic:
        this.textStyle.fontStyle = this.textStyle.fontStyle === format ? '' : format;
        break;

      case TextFormat.underline:
        this.textStyle.textDecoration = this.textStyle.textDecoration === format ? '' : format;
        break;

      default:
        this.textStyle.textAlign = format === this.textStyle.textAlign ? '' : format;
        break;

    }
    this.updateTextStyle(this.textStyle);

  }

  private updateTextStyle(textStyle: StyleHandle) {
    if (this.files[0] && this.files[0].textHandle) {
      this.files[0].textHandle.style = textStyle;
    }
    localStorage.setItem(this.textStorage, JSON.stringify(this.files[0].textHandle));
  }
}
