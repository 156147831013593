<mat-dialog-content class="mat-typography">
    <p class="text-flex">
        Você pode fazer sua arte pelo celular, mas se preferir 
        pode concluir pelo computador devido tamanho da 
        tela e editor com mais recursos.
        <br /><br />
        Você pode selecionar suas fotos do celular e transferir 
        para o computador através do botão “Salvar Fotos”.
        <br /><br />
        Dica: procure encaixar as fotos nos quadros e células 
        considerando se são verticais ou horizontais.
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <mat-checkbox color="warn" [(ngModel)]="checked"><span class="text-flex">Não aparecer novamente</span>
    </mat-checkbox>
    <span style="flex: auto;"></span>
    <button mat-button [mat-dialog-close]="checked" cdkFocusInitial class="btn btn-seize">OK</button>
</mat-dialog-actions>