<div class="text-info" *ngIf="images!.length === 0">
  <p>
    Para importar as fotos do Instagram, você precisará se autenticar com seu
    nome de usuário e senha do Instagram.
  </p>

  <p>
    Nota: A LIFEWALL Photo não salvará seu nome de usuário e senha do Instagram.
    <br />Essas informações são usadas pelo Instagram para autenticar o usuário
    e recuperar as fotos. <br />É totalmente seguro usar este recurso.
  </p>
  <button (click)="login()">Autorizar</button>
</div>

<ng-container *ngIf="images!.length > 0">
  <div
    style="cursor: pointer"
    class="d-flex justify-content-end align-items-center px-3 mt-1"
  >
    <mat-checkbox color="warn" (click)="updateSelection()">
      Selecionar todos
    </mat-checkbox>
  </div>
  <div class="grid">
    <dws-image-container
      *ngFor="let item of images"
      [value]="item"
      [imagePath]="item.media_url"
    >
    </dws-image-container>
  </div>
</ng-container>
