import { Component, OnInit } from '@angular/core';
import { Canvas } from 'src/app/utils/canvas';

@Component({
  selector: 'dws-v13',
  templateUrl: './v13.component.html',
  styleUrls: ['./v13.component.scss']
})
export class V13Component extends Canvas {

  constructor() {
    super(V13Component, 13);
  }

}
