import { Injectable } from '@angular/core';
import { OrientationEnum } from '@app/helpers/enumerators';
import { Size } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class OrientationService {

  private sizeValue: Size;

  constructor() {
    this.sizeValue = {
      small: {
        height: '30',
        wight: '30'
      },
      medium: {
        height: '50',
        wight: '50'
      },
      big: {
        height: '80',
        wight: '80'
      },
    };
  }


  get getSizeValue() {
    return this.sizeValue;
  }

  set setSizeValue(orientation: OrientationEnum) {
    switch (orientation) {

      case OrientationEnum.vertical:
        this.sizeValue = {
          small: {
            height: '38',
            wight: '25'
          },
          medium: {
            height: '63',
            wight: '42'
          },
          big: {
            height: '100',
            wight: '67'
          },
        };
        break;

      case OrientationEnum.horizontal:
        this.sizeValue = {
          small: {
            height: '25',
            wight: '38'
          },
          medium: {
            height: '42',
            wight: '63'
          },
          big: {
            height: '67',
            wight: '100'
          },
        };
        break;

      default:
        this.sizeValue.small.height = '30';
        this.sizeValue.small.wight = '30';

        this.sizeValue.medium.height = '50';
        this.sizeValue.medium.wight = '50';

        this.sizeValue.small.height = '80';
        this.sizeValue.small.wight = '80';
        break;
    }
  }
}
