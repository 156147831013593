import { Component, OnInit } from '@angular/core';
import { StatusDelivery } from '@app/helpers/enumerators';

@Component({
  selector: 'dws-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  status = StatusDelivery;

  produtos = [
    {
      code: 6001,
      product: 'Quadro Quadrado',
      type: 'Trimestral',
      recurrence: 'Mensal',
      dateSignature: '07/2022',
      dateDelivery: '07/2022',
      status: StatusDelivery.Entregue
    },
    {
      code: 6002,
      product: 'Quadro Quadrado',
      type: 'Trimestral',
      recurrence: 'Mensal',
      dateSignature: '07/2022',
      dateDelivery: '08/2022',
      status: StatusDelivery.Enviado
    },
    {
      code: 6003,
      product: 'Quadro Quadrado',
      type: 'Trimestral',
      recurrence: 'Mensal',
      dateSignature: '07/2022',
      dateDelivery: '09/2022',
      status: StatusDelivery.Pendente
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  getStatusClass(status: StatusDelivery) {
    switch (status) {
      case StatusDelivery.Entregue:
        return 'btn btn-success';

      case StatusDelivery.Enviado:
        return 'btn btn btn-info';

      case StatusDelivery.Pendente:
        return 'btn btn btn-warning';

      default:
        return 'btn btn btn-danger';
    }
  }
}
