import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.init';
import { AlertComponent } from './components/alert/alert.component';
import { ApprovalDialogComponent } from './components/approval-dialog/approval-dialog.component';
import { CanvasBaseComponent } from './components/canvas-base/canvas-base.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EditorPhotoComponent } from './components/editor-photo/editor-photo.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GenerateTextComponent } from './components/generate-text/generate-text.component';
import { IconSeizeComponent } from './components/icon-seize/icon-seize.component';
import { LoginComponent } from './pages/signature/login/login.component';
import { ProductConfigurationComponent } from './components/product-configuration/product-configuration.component';
import { RegisterComponent } from './pages/signature/register/register.component';
import { SelectLayoutsComponent } from './components/select-layouts/select-layouts.component';
import { DeviceWrapperComponent } from './components/upload-dialog/device-wrapper/device.component';
import { DropboxWrapperComponent } from './components/upload-dialog/dropbox-wrapper/dropbox-wrapper.component';
import { FacebookWrapperComponent } from './components/upload-dialog/facebook-wrapper/facebook-wrapper.component';
import { ImageContainerComponent } from './components/upload-dialog/image-container/image-container.component';
import { InstragramWrapperComponent } from './components/upload-dialog/instagram-wrapper/instagram.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { AddEditComponent } from './components/users/add-edit/add-edit.component';
import { LayoutComponent } from './components/users/layout/layout.component';
import { ListComponent } from './components/users/list/list.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { ImportWallpaperComponent } from './components/wallpaper/import-wallpaper/import-wallpaper.component';
import { SettingsWallpaperComponent } from './components/wallpaper/settings-wallpaper/settings-wallpaper.component';
import { TemplateWallpaperComponent } from './components/wallpaper/template-wallpaper/template-wallpaper.component';
import { CheckoutComponent } from './core/checkout/checkout.component';
import { ContentComponent } from './core/content/content.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { DragDropDirective } from './helpers/directives/drag-drop.directive';
import { ErrorInterceptor, JwtInterceptor } from './helpers/interceptors';
import { MaterialModule } from './material.module';
import { HomeComponent } from './pages/home/home.component';
import { HorizontalComponent } from './pages/layouts/horizontal/horizontal.component';
import { SquareComponent } from './pages/layouts/square/square.component';
import { VerticalComponent } from './pages/layouts/vertical/vertical.component';
import { H13Component } from './pages/templates/horizontal/h13/h13.component';
import { H13aComponent } from './pages/templates/horizontal/h13a/h13a.component';
import { H4Component } from './pages/templates/horizontal/h4/h4.component';
import { H6Component } from './pages/templates/horizontal/h6/h6.component';
import { H8Component } from './pages/templates/horizontal/h8/h8.component';
import { H8aComponent } from './pages/templates/horizontal/h8a/h8a.component';
import { S10Component } from './pages/templates/square/s10/s10.component';
import { S13Component } from './pages/templates/square/s13/s13.component';
import { S4Component } from './pages/templates/square/s4/s4.component';
import { S7Component } from './pages/templates/square/s7/s7.component';
import { S8Component } from './pages/templates/square/s8/s8.component';
import { S9Component } from './pages/templates/square/s9/s9.component';
import { V13Component } from './pages/templates/vertical/v13/v13.component';
import { V4Component } from './pages/templates/vertical/v4/v4.component';
import { V7Component } from './pages/templates/vertical/v7/v7.component';
import { V8Component } from './pages/templates/vertical/v8/v8.component';
import { V9Component } from './pages/templates/vertical/v9/v9.component';
import { BlackPhotoFramesComponent } from './pages/templates/wallpaper/black-photo-frames/black-photo-frames.component';
import { CollageComponent } from './pages/templates/wallpaper/collage/collage.component';
import { OrthogonalComponent } from './pages/templates/wallpaper/orthogonal/orthogonal.component';
import { ScatteredComponent } from './pages/templates/wallpaper/scattered/scattered.component';
import { WoodPhotoFramesComponent } from './pages/templates/wallpaper/wood-photo-frames/wood-photo-frames.component';
import { WallpaperComponent } from './pages/wallpaper/wallpaper.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { H1Component } from './pages/templates/horizontal/h1/h1/h1.component';
import { S1Component } from './pages/templates/square/s1/s1/s1.component';
import { V1Component } from './pages/templates/vertical/v1/v1/v1.component';
import { ValidationCodeDialogComponent } from './components/validation-code-dialog/validation-code-dialog.component';
import { PhoneMaskDirective } from './helpers/directives/phone-mask.directive';
import { ImageGalleryModalComponent } from './mobile/components/image-gallery-modal/image-gallery-modal.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { PhotoComponent } from './pages/signature/photo/photo.component';
import { RecoverComponent } from './pages/signature/recover/recover.component';
import { DeliveryComponent } from './pages/signature/delivery/delivery.component';
import { AddressComponent } from './pages/signature/register/address/address.component';
import { ClientComponent } from './pages/signature/register/client/client.component';
import { ProfileComponent } from './pages/signature/register/profile/profile.component';
import { ModelArtsComponent } from './pages/signature/register/profile/model-arts/model-arts.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    SelectLayoutsComponent,
    DragDropDirective,
    UploadImageComponent,
    CanvasBaseComponent,
    GenerateTextComponent,
    IconSeizeComponent,
    SquareComponent,
    HorizontalComponent,
    H4Component,
    H6Component,
    H8Component,
    H8aComponent,
    H13Component,
    H13aComponent,
    S4Component,
    S7Component,
    S8Component,
    S9Component,
    S10Component,
    S13Component,
    VerticalComponent,
    V4Component,
    V7Component,
    V8Component,
    V9Component,
    V13Component,
    LoginComponent,
    RegisterComponent,
    AlertComponent,
    AddEditComponent,
    LayoutComponent,
    ListComponent,
    ProductConfigurationComponent,
    ApprovalDialogComponent,
    GalleryComponent,
    UploadTaskComponent,
    WallpaperComponent,
    TemplateWallpaperComponent,
    ImportWallpaperComponent,
    SettingsWallpaperComponent,
    ScatteredComponent,
    OrthogonalComponent,
    BlackPhotoFramesComponent,
    WoodPhotoFramesComponent,
    CheckoutComponent,
    EditorPhotoComponent,
    CollageComponent,
    VideoDialogComponent,
    UploadDialogComponent,
    FacebookWrapperComponent,
    DropboxWrapperComponent,
    ImageContainerComponent,
    InstragramWrapperComponent,
    DeviceWrapperComponent,
    ConfirmDialogComponent,
    PageNotFoundComponent,
    AlertDialogComponent,
    H1Component,
    S1Component,
    V1Component,
    ValidationCodeDialogComponent,
    PhoneMaskDirective,
    ImageGalleryModalComponent,
    SignatureComponent,
    PhotoComponent,
    RecoverComponent,
    DeliveryComponent,
    AddressComponent,
    ClientComponent,
    ProfileComponent,
    ModelArtsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    NgbModule,
    NgxPhotoEditorModule,
    ReactiveFormsModule,
    HttpClientModule,
    SimplebarAngularModule,
    NgxSpinnerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [HttpClient],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
