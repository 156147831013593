import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dws-model-arts',
  templateUrl: './model-arts.component.html',
  styleUrls: ['./model-arts.component.scss']
})
export class ModelArtsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
