import { Component, OnInit } from '@angular/core';
import { GalleryService } from '@app/api';
import { FileHandle, Gallery } from '@app/models';
import { Canvas } from '@app/utils/canvas';
import { environment } from '@environments/environment';

@Component({
  selector: 'dws-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  uriImagens = `${environment.urlBackend}/images`;
  selectedGallery = 'photo';
  titleGallery: TypeGallery;

  files: FileHandle[] = [];
  filesPresentation: FileHandle[] = [];

  page = 0;
  size = 9;

  get connectedTo(): string[] {
    return [
      ...Canvas.listIdConnection,
    ];
  }

  constructor(private galleryService: GalleryService) { }

  ngOnInit(): void {
    this.imagensGallery(this.selectedGallery);
  }

  getData(obj: any) {
    let index = 0;
    const startingIndex = obj.pageIndex * obj.pageSize;
    const endingIndex = startingIndex + obj.pageSize;

    this.files = this.filesPresentation.filter(() => {
      index++;
      return (index > startingIndex && index <= endingIndex) ? true : false;
    });
  }

  clickType(type: string): void {
    this.imagensGallery(type);
  }

  private imagensGallery(type: string) {
    this.page = 0;
    this.selectedGallery = type;
    this.titleGallery = this.getValueByKey(this.selectedGallery);
    this.filesPresentation = [];
    this.galleryService.getByType(type).subscribe(res => {
      const files = res.results as Gallery[];
      for (const file of files) {
        const urlImage = `${file.imageUrl}`;
        const imageFile = new File([urlImage], file.imageName, { type: 'image/jpeg' });
        const isSticker = file.type === 'sticker';
        this.filesPresentation.push({ file: imageFile, url: urlImage, strUrl: '', textHandle: undefined, selected: false, isSticker });
      }
      this.getData({ pageIndex: this.page, pageSize: this.size });
    });
  }

  private getValueByKey(key: string) {

    switch (key) {
      case 'illustration':
        return TypeGallery.illustration;

      case 'sampler':
        return TypeGallery.sampler;

      case 'sticker':
        return TypeGallery.sticker;

      default:
        return TypeGallery.photo;
    }
  }
}

export enum TypeGallery {
  photo = 'Foto',
  illustration = 'Ilustração',
  sampler = 'Padrão',
  sticker = 'Sticker'
}
