import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService, WallpaperService } from '@app/api';
import { ConfirmDialogComponent } from '@app/components/confirm-dialog/confirm-dialog.component';
import { UploadDialogComponent } from '@app/components/upload-dialog/upload-dialog.component';
import { OrientationEnum } from '@app/helpers/enumerators';
import { FileHandle } from '@app/models';
import { Globals } from '@app/utils/globals';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { Attributes } from './../../../models/attributes.model';
import { ProductService } from './../../../services/product.service';

@Component({
  selector: 'dws-import-wallpaper',
  templateUrl: './import-wallpaper.component.html',
  styleUrls: ['./import-wallpaper.component.scss'],
})
export class ImportWallpaperComponent implements OnInit {
  private indexEditable: number;

  files: FileHandle[] = [];

  imageUrl: string;
  imageFile: File;
  borderSize = '4';
  borderColor = '#FFFFFF';
  attributes: Attributes;

  get disabledQtdPhotos() {
    return this.qtdImagens < this.qtdImagensRequeridas || this.qtdImagens > this.qtdIdeal;
  }

  get qtdImagens() {
    return this.files.length;
  }

  get qtdImagensRequeridas() {
    return this.attributes?.minimo;
  }

  get qtdIdeal() {
    return this.attributes?.maximo;
  }

  get filesSelected(): boolean {
    return this.files.filter((x) => x.selected).length > 0;
  }

  get isMobile() {
      return Globals.isMobile;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService,
    private wallpaperService: WallpaperService,
    private dialog: MatDialog,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    const sku = localStorage.getItem('sku') || '';
    const orientation = localStorage.getItem('orientation') || '';
    const largura = localStorage.getItem('largura') || '';
    this.productService
      .obterAtributo(sku, orientation, Number(largura))
      .subscribe((atributo) => (this.attributes = atributo));

    this.getImages();

    if (location.href.includes(`instagram`)) {
      this.openDialogUpload();
    }
  }

  openDialogUpload() {
    const dialog = this.dialog.open(UploadDialogComponent, {
      maxWidth: '98vw',
      height: window.innerWidth < 420 ? '90vh' : '680px',
      width: window.innerWidth < 420 ? '93vw' : '960px',
      panelClass: 'upload-modal-container',
      disableClose: true,
    });

    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((files: FileHandle[]) => {
        if (files && files.length > 0) {
          this.files = [...this.files, ...files];
        }
      });
  }

  getImages() {
    this.spinner.show();
    this.uploadService
      .getByFolder()
      .subscribe((res) => {
        for (const img of res.results) {
          const imageUrl = `${img.imageUrl}`;
          const imgFile = new File([imageUrl], img.imageName, {
            type: 'image/jpeg',
          });
          const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
          const file: FileHandle = {
            _id: img.code ? img.code.toString() : img._id,
            file: imgFile,
            url: urlSafe,
            selected: false,
            strUrl: imageUrl,
          };
          this.files.push(file);
        }
      })
      .add(() => this.spinner.hide());
  }

  filesInput(evt: Event) {
    const files = (evt.target as HTMLInputElement).files as FileList;
    this.filesDropped(files);
  }

  filesDropped(files: FileList) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        this.spinner.show();
        this.uploadService
          .create(files[key])
          .subscribe((res) => {
            const imageUrl = `${res.imageUrl}`;
            const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
            const fileHandle: FileHandle = {
              _id: res.code ? res.code.toString() : res._id,
              file: files[key],
              url: urlSafe,
              selected: false,
              strUrl: imageUrl,
            };
            this.uploadService
              .getImageDimension(fileHandle)
              .subscribe((response) => {
                if (response.width < 600 || response.height < 600) {
                  fileHandle.baixaresolucao = true;
                }
                fileHandle.baixaresolucao = false;
                this.files.push(fileHandle);
              });
          })
          .add(() => this.spinner.hide());
      }
    }
  }

  updateImage(event: CroppedEvent) {
    this.spinner.show();
    const file = event.file || ({} as File);
    const id = this.files[this.indexEditable]._id || '';
    this.uploadService.update(file, id).subscribe({
      next: (resp) => {
        const imagem = this.files[this.indexEditable];
        const imageUrl = `${resp.imageUrl}`;
        const urlSafe = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        const fileHandle: FileHandle = {
          _id: imagem._id,
          file: event.file,
          url: urlSafe,
          selected: false,
          strUrl: imageUrl,
        };
        this.files.splice(this.indexEditable, 1, fileHandle);
        this.spinner.hide();
      },
      error: (err) => console.log(err),
      complete: () => this.spinner.hide(),
    });
  }

  deleteImage(index: number) {
    const id = this.files[index]._id || '';
    this.uploadService.delete(id).subscribe(() => {
      this.files.splice(index, 1);
    });
  }

  selectAll(checked: boolean) {
    for (const file of this.files) {
      this.selectImage({ checked, file });
    }
  }

  selectImage(event: any) {
    const index: number = this.files.indexOf(event.file);
    this.files[index].selected = event.checked;
  }

  editImage(event: any) {
    this.indexEditable = event.index;
    this.imageUrl = event.file.url;
    this.imageFile = event.file.file;
  }

  excludeImageSelectedOrAll() {
    if (this.filesSelected) {
      const filesSelected = this.files.filter((x) => x.selected);
      this.deleteFiles(filesSelected);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '200px',
        data: this.files.length,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.deleteFiles(this.files);
        }
      });
    }
  }

  changeBorderSize(event: Event) {
    this.borderSize = (event.target as HTMLSelectElement).value;
    const sizeBorder = `${Number(this.borderSize) * 0.1}vw`;
    document.documentElement.style.setProperty(
      '--cells-border-size',
      sizeBorder
    );
  }

  changeBorderColor(event: Event) {
    this.borderColor = (event.target as HTMLSelectElement).value;
    document.documentElement.style.setProperty(
      '--cells-border-color',
      this.borderColor
    );
  }

  generateWallpaper() {
    if (!this.disabledQtdPhotos) {
      Globals.loadingText =
        'Aguarde um pouco, estamos montando seu papel de parede, isto pode demorar alguns poucos minutos, mas já ficará pronto. Obrigado!';

      this.spinner.show();
      const sku = localStorage.getItem('sku') || '';
      const orientationEnum = this.definirOrientacao(sku);

      switch (this.callMethod(sku)) {
        case 'moldure':
          const tipoMoldura = this.definirTipoMoldura(sku);
          this.wallpaperService
            .moldure(orientationEnum, tipoMoldura)
            .subscribe((res) => {
              Globals.urlWallpaper = res.thumb;
            })
            .add(() => this.spinner.hide());
          break;

        case 'collage':
          this.wallpaperService
            .collage(orientationEnum)
            .subscribe((res) => {
              Globals.urlWallpaper = res.thumb;
            })
            .add(() => this.spinner.hide());
          break;

        case 'grid':
          this.wallpaperService
            .grid(orientationEnum)
            .subscribe((res) => {
              Globals.urlWallpaper = res.thumb;
            })
            .add(() => this.spinner.hide());
          break;

        case 'gridInclination':
          this.wallpaperService
            .gridInclination(orientationEnum)
            .subscribe((res) => {
              console.log(res);
              Globals.urlWallpaper = res.thumb;
            })
            .add(() => this.spinner.hide());
          break;
      }
    }
  }

  private callMethod(sku: string): string {
    switch (sku) {
      case 'pp_h_mod01':
      case 'pp_h_mod02':
      case 'pp_v_mod01':
      case 'pp_v_mod02':
        return 'moldure';

      case 'pp_h_mod03':
      case 'pp_v_mod03':
        return 'collage';

      case 'pp_h_mod04':
      case 'pp_v_mod04':
        return 'grid';

      case 'pp_h_mod05':
      case 'pp_v_mod05':
        return 'gridInclination';

      default:
        return '';
    }
  }

  private definirTipoMoldura(sku: string) {
    switch (sku) {
      case 'pp_v_mod01':
      case 'pp_h_mod01':
        return 'preta';

      case 'pp_v_mod02':
      case 'pp_h_mod02':
        return 'madeira';

      default:
        return '';
    }
  }

  private definirOrientacao(sku: string) {
    if (sku.includes('_v_')) {
      return OrientationEnum.vertical;
    }

    if (sku.includes('_h_')) {
      return OrientationEnum.horizontal;
    }

    return OrientationEnum.square;
  }

  private deleteFiles(files: FileHandle[]) {
    for (const key in files) {
      if (Object.prototype.hasOwnProperty.call(files, key)) {
        const file = this.files[key];
        this.uploadService.delete(file._id || '').subscribe(() => {
          const index = this.files.indexOf(file);
          this.files.splice(index, 1);
        });
      }
    }
  }
}
