import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DropboxService {
  static ACCESS_TOKEN_KEY = 'access_token_dropbox';
  accessToken = localStorage.getItem(DropboxService.ACCESS_TOKEN_KEY);

  constructor(private http: HttpClient) {}

  images(): Observable<any> {
    return this.http
      .post(
        'https://api.dropboxapi.com/2/files/list_folder',
        {
          path: '',
        },
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      )
      .pipe(
        switchMap((data: any) =>
          zip(
            ...data!.entries!.map((item: any) =>
              this.http.post(
                'https://api.dropboxapi.com/2/files/get_temporary_link',
                { path: item.path_lower },
                {
                  headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                  },
                }
              )
            )
          )
        )
      );
  }
}
