<section class="content">
    <section class="container">
        <h2>Relatorio de entregas</h2>
        <div class="space"></div>
        <hr />
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Produto</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Recorrência</th>
                    <th scope="col">Mês Assinatura</th>
                    <th scope="col">Mês Entrega</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of produtos">
                    <th scope="row">{{ item.code }}</th>
                    <td>{{ item.product }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.recurrence }}</td>
                    <td>{{ item.dateSignature }}</td>
                    <td>{{ item.dateDelivery }}</td>
                    <td>
                        <button type="button" [class]="getStatusClass(item.status)">{{ status[item.status] }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</section>