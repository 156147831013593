<div class="text-info" *ngIf="!(images$ | async)">
  <p>
    Para importar as fotos do Dropbox, você precisará se autenticar com seu nome
    de usuário e senha do Dropbox.
  </p>

  <p>
    Nota: A Seize Photo não salvará seu nome de usuário e senha do Dropbox.
    <br />Essas informações são usadas pelo Dropbox para autenticar o usuário e
    recuperar as fotos. <br />É totalmente seguro usar este recurso.
  </p>
  <button (click)="openDropbox()">Autorizar</button>
</div>

<div *ngIf="images$ | async as images" class="d-flex flex-column">
  <div
    style="cursor: pointer"
    class="d-flex justify-content-end align-items-center px-3 mt-1"
  >
    <mat-checkbox color="warn" (click)="updateSelection()">
      Selecionar todos
    </mat-checkbox>
  </div>
  <div class="d-flex">
    <div class="flex-column">
      <dws-image-container
        class="mr-1"
        *ngFor="let image of images"
        [value]="image"
        [imagePath]="image.link"
      ></dws-image-container>
    </div>
  </div>
</div>
