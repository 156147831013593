import { Component } from '@angular/core';
import { Canvas } from '@app/utils/canvas';

@Component({
  selector: 'dws-s1',
  templateUrl: './s1.component.html',
  styleUrls: ['./s1.component.scss']
})
export class S1Component extends Canvas {

  constructor() {
    super(S1Component, 1);
  }

}
