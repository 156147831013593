import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrientationEnum } from '@app/helpers/enumerators';
import { ResultWallpaper } from '@app/models/result-wallpaper.model';
import { AlertService } from '@app/services';
import { ServiceBase } from '@app/utils/service-base';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WallpaperService extends ServiceBase {

  private uri = `${environment.urlBackend}/wallpaper`;

  constructor(private http: HttpClient, alertService: AlertService) {
    super(alertService);
  }

  moldure(orientation: OrientationEnum, tipo: string): Observable<ResultWallpaper> {
    const formData = this.createFormData(orientation);
    formData.append('tipo', tipo);
    return this.http.post<ResultWallpaper>(`${this.uri}/moldure/`, formData, this.createOptions())
      .pipe(tap((res: ResultWallpaper) => {
        localStorage.setItem('wasGenerated', 'true');
        return res;
      }), catchError(this.handleError));
  }

  collage(orientation: OrientationEnum): Observable<ResultWallpaper> {
    const formData = this.createFormData(orientation);
    return this.http.post<ResultWallpaper>(`${this.uri}/collage/`, formData, this.createOptions())
      .pipe(tap((res: ResultWallpaper) => {
        localStorage.setItem('wasGenerated', 'true');
        return res;
      }), catchError(this.handleError));
  }

  grid(orientation: OrientationEnum): Observable<ResultWallpaper> {
    const formData = this.createFormData(orientation);

    return this.http.post<ResultWallpaper>(`${this.uri}/grid/`, formData, this.createOptions())
      .pipe(tap((res: ResultWallpaper) => {
        localStorage.setItem('wasGenerated', 'true');
        return res;
      }), catchError(this.handleError));
  }

  gridInclination(orientation: OrientationEnum): Observable<ResultWallpaper> {
    const formData = this.createFormData(orientation);
    formData.append('colunas', '3');
    return this.http.post<ResultWallpaper>(`${this.uri}/grid-inclination/`, formData, this.createOptions())
      .pipe(tap((res: ResultWallpaper) => {
        localStorage.setItem('wasGenerated', 'true');
        return res;
      }), catchError(this.handleError));
  }

  private createFormData(orientation: OrientationEnum) {
    const formData = new FormData();
    const largura = localStorage.getItem('largura');
    switch (orientation) {
      case OrientationEnum.vertical:
        if (largura === '80') {
          formData.append('formato', '1');
        } else if (largura === '130') {
          formData.append('formato', '2');
        }
        break;

      case OrientationEnum.horizontal:
        if (largura === '100' || largura === '75' || largura === '50') {
          formData.append('formato', '3');
        }
        else if (largura === '150' || largura === '130' || largura === '70') {
          formData.append('formato', '5');
        }
    }
    return formData;
  }
}
